import { generalList } from './miscellaneous/general';
import { conceptsList } from './miscellaneous/concepts';

export function MiscellaneousRedirects() {
  /**
 This file has specific redirects for all paths under `/docs/` that are under different sub paths
 This is a legacy format - Do not use this file/format moving forward - follow instructions under hash/README instead
 */
  const match = location.pathname + location.hash;
  const generalPrefix = '/docs/';
  const conceptsPrefix = `${generalPrefix}concepts/`;

  const list = generalList.concat(
    conceptsList.map((item) => {
      item.push(true);
      return item;
    }),
  );

  for (let index = 0; index < list.length; ++index) {
    const item = list[index];
    const prefix = item[2] ? conceptsPrefix : generalPrefix;
    if (`${prefix}${item[0]}` === match) {
      document.location = `${prefix}${item[1]}`;
      return;
    }
  }
}
