export const conceptsList = [
  //Observability section redirects
  [
    'dashboard-features/monitoring#monitoring-chart',
    'observability/monitoring#monitoring-chart',
  ],
  [
    'dashboard-features/monitoring#example-queries',
    'observability/monitoring#example-queries',
  ],
  [
    'dashboard-features/monitoring#use-case-example',
    'observability/monitoring#use-case-example',
  ],
  [
    'dashboard-features/monitoring#create-a-new-query',
    'observability/monitoring#create-a-new-query',
  ],
  [
    'dashboard-features/monitoring#visualize',
    'observability/monitoring#visualize',
  ],
  ['dashboard-features/monitoring#where', 'observability/monitoring#where'],
  [
    'dashboard-features/monitoring#group-by',
    'observability/monitoring#group-by',
  ],
  ['dashboard-features/monitoring#limit', 'observability/monitoring#limit'],
  [
    'dashboard-features/monitoring#create-a-test-query-for-demo',
    'observability/monitoring#create-a-test-query-for-demo',
  ],
  [
    'dashboard-features/monitoring#saved-personal-queries',
    'observability/monitoring#saved-personal-queries',
  ],
  [
    'dashboard-features/monitoring#saved-team-queries',
    'observability/monitoring#saved-team-queries',
  ],
  [
    'dashboard-features/monitoring#managing-saved-queries',
    'observability/monitoring#managing-saved-queries',
  ],
  ['dashboard-features/monitoring#limits', 'observability/monitoring#limits'],
  [
    'dashboard-features/monitoring#error-messages',
    'observability/monitoring#error-messages',
  ],
  [
    'deployments/runtime-logs#what-are-runtime-logs',
    'observability/runtime-logs#what-are-runtime-logs',
  ],
  [
    'deployments/runtime-logs#viewing-logs',
    'observability/runtime-logs#viewing-logs',
  ],
  [
    'deployments/runtime-logs#filter-log-results',
    'observability/runtime-logs#filter-log-results',
  ],
  [
    'deployments/runtime-logs#log-filters',
    'observability/runtime-logs#log-filters',
  ],
  ['deployments/runtime-logs#timeline', 'observability/runtime-logs#timeline'],
  ['deployments/runtime-logs#level', 'observability/runtime-logs#level'],
  ['deployments/runtime-logs#project', 'observability/runtime-logs#project'],
  ['deployments/runtime-logs#function', 'observability/runtime-logs#function'],
  ['deployments/runtime-logs#domain', 'observability/runtime-logs#domain'],
  ['deployments/runtime-logs#type', 'observability/runtime-logs#type'],
  [
    'deployments/runtime-logs#search-logs-field',
    'observability/runtime-logs#search-logs-field',
  ],
  [
    'deployments/runtime-logs#log-details',
    'observability/runtime-logs#log-details',
  ],
  [
    'deployments/runtime-logs#showing-additional-logs',
    'observability/runtime-logs#showing-additional-logs',
  ],
  [
    'deployments/runtime-logs#log-sharing',
    'observability/runtime-logs#log-sharing',
  ],
  [
    'dashboard-features/activity-log#activity-tab-location',
    'observability/activity-log#activity-tab-location',
  ],
  [
    'dashboard-features/activity-log#when-to-use-the-activity-log',
    'observability/activity-log#when-to-use-the-activity-log',
  ],
  [
    'dashboard-features/activity-log#events-logged',
    'observability/activity-log#events-logged',
  ],
  [
    'teams-and-accounts/security-and-compliance#audit-logs',
    'observability/audit-log',
  ],

  // Comments redirects
  ['comments#github-integration', 'comments#git-provider-integration'],

  [
    'deployments/comments/how-comments-work#enable-comments-from-people-outside-your-team',
    'deployments/comments/how-comments-work#sharing',
  ],

  // Password and SSO protection redirects
  [
    'projects/overview#sso-protection',
    'security/deployment-protection/methods-to-protect-deployments/vercel-authentication',
  ],
  [
    'projects/overview#protecting-preview-deployments',
    'security/deployment-protection#protecting-only-preview-deployments',
  ],
  [
    'projects/overview#protecting-production-deployments',
    'security/deployment-protection#protecting-only-production-deployments',
  ],
  [
    'projects/overview#details',
    'security/deployment-protection/methods-to-protect-deployments/vercel-authentication',
  ],
  [
    'projects/overview#disabling-the-feature',
    'security/deployment-protection#disable-advanced-deployment-protection',
  ],
  [
    'projects/overview#bypassing-password-protection-programmatically',
    'security/deployment-protection/methods-to-bypass-deployment-protection/protection-bypass-automation',
  ],
  [
    'deployments/preview-deployments#turning-off-preview-deployments',
    'security/deployment-protection#advanced-deployment-protection',
  ],

  // Analytics redirects

  ['analytics#limits', 'analytics/limits-and-pricing'],
  ['analytics#pricing', 'analytics/limits-and-pricing#pricing'],
  ['analytics#prorating', 'analytics/limits-and-pricing#prorating'],
  ['analytics#sample-rate', 'analytics/limits-and-pricing#sample-rate'],
  [
    'analytics#reporting-window-for-data-points',
    'analytics/limits-and-pricing#reporting-window-for-data-points',
  ],
  [
    'analytics#maximum-number-of-data-points-per-day',
    'analytics/limits-and-pricing#maximum-number-of-data-points-per-day',
  ],
  ['git#monorepos', 'projects/monorepos'],
  // Teams & Accounts Docs redirects
  [
    'personal-accounts/overview#understanding-the-personal-account',
    'teams-and-accounts/',
  ],
  [
    'personal-accounts/overview#personal-space',
    'teams-and-accounts#creating-a-personal-account',
  ],
  [
    'personal-accounts/overview',
    'teams-and-accounts#creating-a-personal-account',
  ],
  [
    'personal-accounts/overview#login-methods',
    'teams-and-accounts#login-methods-and-connections',
  ],
  [
    'personal-accounts/login-connections',
    'teams-and-accounts#login-methods-and-connections',
  ],
  [
    'personal-accounts/login-connections#adding-a-login-connection',
    'teams-and-accounts#login-methods-and-connections',
  ],
  [
    'personal-accounts/login-connections#disconnecting-a-login-connection',
    'teams-and-accounts#login-methods-and-connections',
  ],
  // Security redirects
  [
    'edge-network/encryption#supported-tls-versions',
    'security/encryption#supported-tls-versions',
  ],
  [
    'edge-network/encryption#tls-resumption',
    'security/encryption#tls-resumption',
  ],
  [
    'edge-network/encryption#ocsp-stapling',
    'security/encryption#ocsp-stapling',
  ],
  [
    'edge-network/encryption#supported-ciphers',
    'security/encryption#supported-ciphers',
  ],
  [
    'edge-network/encryption#support-for-hsts',
    'security/encryption#support-for-hsts',
  ],
  [
    'edge-network/encryption#how-certificates-are-handled',
    'security/encryption#how-certificates-are-handled',
  ],
  [
    'edge-network/encryption#full-specification',
    'security/encryption#full-specification',
  ],
  // Accounts redirects

  [
    'teams/overview#configuring-user-or-team-settings',
    'teams-and-accounts/team-members-and-roles#managing-team-members',
  ],
  [
    'teams/members#invite-link',
    'teams-and-accounts/team-members-and-roles#invite-link',
  ],
  [
    'teams/members#request-access',
    'teams-and-accounts/team-members-and-roles#request-access',
  ],
  [
    'teams/members#team-email-domain',
    'teams-and-accounts/team-members-and-roles#team-email-domain',
  ],
  [
    'teams/roles-and-permissions#pro-team-account-roles',
    'teams-and-accounts/team-members-and-roles#pro-team-roles',
  ],
  [
    'teams/roles-and-permissions#owner',
    'teams-and-accounts/team-members-and-roles#owner-role',
  ],
  [
    'teams/roles-and-permissions#member',
    'teams-and-accounts/team-members-and-roles#member-role',
  ],
  [
    'teams/roles-and-permissions#enterprise-team-account-roles',
    'teams-and-accounts/team-members-and-roles#enterprise-team-roles',
  ],
  [
    'teams/roles-and-permissions#developer',
    'teams-and-accounts/team-members-and-roles#developer-role',
  ],
  [
    'teams/roles-and-permissions#project-administrators',
    'teams-and-accounts/team-members-and-roles#project-administrators',
  ],
  [
    'teams/roles-and-permissions#billing',
    'teams-and-accounts/team-members-and-roles#billing-role',
  ],
  [
    'teams/roles-and-permissions#viewer',
    'teams-and-accounts/team-members-and-roles#viewer-role',
  ],
  [
    'teams/roles-and-permissions#role-permissions',
    'teams-and-accounts/team-members-and-roles#role-permissions-comparison',
  ],
  ['teams/security-and-compliance#audit-logs', 'observability/audit-log'],
  [
    'teams-and-accounts/security-and-compliance#audit-logs',
    'observability/audit-log',
  ],
  [
    'teams/security-and-compliance#csv-file-structure',
    'teams-and-accounts/security-and-compliance#csv-file-structure',
  ],
  [
    'teams/security-and-compliance#understanding-a-log-entry',
    'teams-and-accounts/security-and-compliance#understanding-a-log-entry',
  ],
  [
    'teams/security-and-compliance#saml-single-sign-on',
    'teams-and-accounts/security-and-compliance#saml-single-sign-on',
  ],
  [
    'teams/security-and-compliance#configuring-saml-sso',
    'teams-and-accounts/security-and-compliance#configuring-saml-sso',
  ],
  [
    'teams/security-and-compliance#authenticating-with-saml-sso',
    'teams-and-accounts/security-and-compliance#authenticating-with-saml-sso',
  ],
  [
    'teams-and-accounts/security-and-compliance#enforcing-saml',
    'security/saml#enforcing-saml',
  ],
  [
    'teams/security-and-compliance#enforcing-saml',
    'teams-and-accounts/security-and-compliance#enforcing-saml',
  ],
  [
    'teams/security-and-compliance#directory-sync',
    'teams-and-accounts/security-and-compliance#directory-sync',
  ],
  [
    'teams/security-and-compliance#configuring-directory-sync',
    'security/directory-sync#configuring-directory-sync',
  ],
  [
    'platform/login-connections',
    'teams-and-accounts#login-methods-and-connections',
  ],
  //Deployment redirects
  ['deployments/overview#deployment-types', 'deployments/environments'],
  ['deployments/overview#production', 'deployments/environments#production'],
  ['deployments/overview#preview', 'deployments/preview-deployments'],
  ['deployments/environments#preview', 'deployments/preview-deployments'],
  [
    'deployments/overview#preview-deployment-suffix',
    'deployments/automatic-urls#preview-deployment-suffix',
  ],
  ['introduction#deployment', 'deployments/overview'],
  ['introduction#preview-deployments', 'deployments/preview-deployments'],
  [
    'introduction#production-deployments',
    'deployments/environments#production',
  ],
  [
    'deployments/automatic-urls#automatic-branch-urls',
    'deployments/generated-urls#url-with-git-branch',
  ],
  [
    'deployments/automatic-urls#automatic-project-urls',
    'deployments/generated-urls',
  ],
  [
    'deployments/automatic-urls#automatic-team-member-urls',
    'deployments/generated-urls',
  ],
  [
    'personal-accounts/team-membership#requesting-access-to-suggested-teams',
    'teams/team-membership#requesting-access-to-suggested-teams',
  ],
  [
    'personal-accounts/team-membership#dismissing-suggested-teams',
    'teams/team-membership#dismissing-suggested-teams',
  ],
  ['personal-accounts/overview#identity', 'teams-and-accounts/'],

  ['limits/usage#number-of-artifacts', 'limits/usage#time-saved'],
  [
    'limits/usage#total-size-of-artifacts',
    'limits/usage#total-size-of-remote-cache-artifacts',
  ],
  [
    'functions/edge-functions/streaming#how-to-stream-data-in-an-edge-function',
    'functions/edge-functions/streaming#streaming-data-with-edge-functions',
  ],
  [
    'functions/edge-functions/streaming#running-asynchronous-processes-on-streamed-data',
    'functions/edge-functions/streaming#streaming-data-with-edge-functions',
  ],
  [
    'functions/edge-functions/streaming#caveats',
    'functions/edge-functions/streaming#constraints',
  ],
  [
    'edge-network/regions#setting-serverless-function-regions',
    'functions/serverless-functions/regions#override-your-default-serverless-region',
  ],
  [
    'edge-network/regions#setting-a-default-region-for-serverless-functions',
    'functions/serverless-functions/regions#select-a-default-serverless-region',
  ],
  [
    'edge-network/regions#setting-edge-function-regions',
    'functions/edge-functions#edge-function-regions',
  ],
  ['edge-network/regions#routing', 'edge-network/regions#region-list'],
  [
    'edge-network/caching#serverless-functions-(lambdas)',
    'edge-network/caching#serverless-functions-lambdas',
  ],
  [
    'edge-network/edge-config/edge-config-api#make-requests-to-connection-urls',
    'edge-network/edge-config/vercel-api#make-requests-to-the-edge-config-endpoint',
  ],
  [
    'edge-network/edge-config/edge-config-api#create-an-edge-config',
    'edge-network/edge-config/vercel-api#create-an-edge-config',
  ],
  [
    'edge-network/edge-config/edge-config-api#update-your-edge-config',
    'edge-network/edge-config/vercel-api#update-your-edge-config',
  ],
  [
    'edge-network/edge-config/edge-config-api#failing-edge-config-patch-requests',
    'edge-network/edge-config/vercel-api#failing-edge-config-patch-requests',
  ],
  [
    'edge-network/edge-config/edge-config-api#read-all-items',
    'edge-network/edge-config/vercel-api#read-all-items',
  ],
  [
    'edge-network/edge-config/edge-config-api#read-metadata',
    'edge-network/edge-config/vercel-api#read-metadata',
  ],
  [
    'edge-network/edge-config/edge-config-api#list-all-edge-configs',
    'edge-network/edge-config/vercel-api#list-all-edge-configs',
  ],
  [
    'edge-network/edge-config/edge-config-api#make-requests-to-the-edge-config-endpoint',
    'edge-network/edge-config/vercel-api#make-requests-to-the-edge-config-endpoint',
  ],
  [
    'edge-network/edge-config/edge-config-apii#request-all-items',
    'edge-network/edge-config/vercel-apii#request-all-items',
  ],
  [
    'edge-network/edge-config/edge-config-api#request-a-single-item',
    'edge-network/edge-config/vercel-api#request-a-single-item',
  ],
  [
    'edge-network/edge-config/edge-config-api#request-the-digest',
    'edge-network/edge-config/vercel-api#request-the-digest',
  ],
  [
    'edge-network/edge-config/edge-config-sdk#use-connection-urls',
    'edge-network/edge-config/edge-config-sdk#use-connection-strings',
  ],
  [
    'edge-network/edge-config/get-started#use-a-connection-url',
    'edge-network/edge-config/get-started#use-a-connection-string',
  ],
  [
    'teams/overview#concurrent-builds',
    'deployments/build-step#concurrent-builds',
  ],
  ['introduction#build', 'deployments/build-step'],
  ['introduction#build-step', 'deployments/build-step'],
  ['introduction#run-time', 'serverless-functions/introduction'],
  ['introduction#custom-domains', 'projects/custom-domains'],
  ['introduction#source-files', 'deployments/overview'],
  ['introduction#serverless-function', 'serverless-functions/introduction'],
  ['introduction#static-file', 'deployments/overview'],
  ['introduction#project', 'projects/overview'],
  ['introduction#apex-domain', 'projects/custom-domains'],
  ['introduction#tld', 'projects/custom-domains'],
  ['errors#errors/platform-version-detection', 'introduction'],
  ['errors#errors/what-can-i-deploy-with-vercel', 'introduction'],
  ['analytics#privacy', 'analytics/privacy'],
  /**
  [
    'analytics#how-the-data-points-are-tracked',
    'analytics/privacy#how-the-data-points-are-tracked',
  ],
  ['analytics#metrics', 'analytics/web-vitals'],
  [
    'analytics#how-the-scores-are-color-coded',
    'analytics/web-vitals#interpreting-performance-scores',
  ],
  [
    'analytics#how-the-percentages-are-calculated',
    'analytics/web-vitals#how-the-percentages-are-calculated',
  ],
  [
    'analytics#how-the-scores-are-determined',
    'analytics/web-vitals#how-the-scores-are-determined',
  ],
  [
    'analytics#first-input-delay-(fid)',
    'analytics/web-vitals#first-input-delay-(fid)',
  ],
  [
    'analytics#cumulative-layout-shift-(cls)',
    'analytics/web-vitals#cumulative-layout-shift-(cls)',
  ],
  [
    'analytics#largest-contentful-paint-(lcp)',
    'analytics/web-vitals#largest-contentful-paint-(lcp)',
  ],
  [
    'analytics#first-contentful-paint-(fcp)',
    'analytics/web-vitals#first-contentful-paint-(fcp)',
  ],
  ['analytics#web-vitals', 'analytics/web-vitals'],
  [
    'analytics#real-experience-score',
    'analytics/web-vitals#real-experience-score-(res)',
  ],
   */
  [
    'deployments/build-step#using-environment-variables-and-secrets',
    'projects/environment-variables',
  ],
  // Environment Variables redirects
  [
    'projects/environment-variables#reserved-environment-variables',
    'projects/environment-variables/reserved-environment-variables',
  ],
  [
    'projects/environment-variables#system-environment-variables',
    'projects/environment-variables/system-environment-variables',
  ],
  [
    'deployments/checks#web-vitals',
    'deployments/checks#virtual-experience-score',
  ],
  ['deployments/checks#scores', 'deployments/checks#virtual-experience-score'],
  ['deployments/checks#metric-values', 'deployments/checks#score-values'],
  [
    'release-phases#deprecated-&-sunset',
    'release-phases#deprecated-and-sunset',
  ],
  [
    'solutions/databases#allowing-&-blocking-ip-addresses',
    'solutions/databases#allowing-and-blocking-ip-addresses',
  ],
  ['personal-accounts/overview#identity', 'teams-and-accounts/'],
  ['personal-accounts/command-menu', 'dashboard-features/command-menu'],
  ['limits/usage#number-of-artifacts', 'limits/usage#time-saved'],
  [
    'limits/usage#total-size-of-artifacts',
    'limits/usage#total-size-of-remote-cache-artifacts',
  ],
  [
    'limits/overview#edge-middleware-and-edge-functions-cpu-limits',
    'limits/overview#edge-middleware-cpu-limit',
  ],
  [
    'limits/overview#edge-functions-invocations',
    'limits/overview#edge-middleware-and-edge-functions-size',
  ],
  [
    'edge-network/caching#serverless-functions-(lambdas)',
    'edge-network/caching#serverless-functions-lambdas',
  ],
  [
    'teams/overview#concurrent-builds',
    'deployments/build-step#concurrent-builds',
  ],
  ['next.js/image-optimization', 'image-optimization'],
  [
    'next.js/image-optimization#usage',
    'image-optimization#edge-network-optimization',
  ],
  [
    'next.js/image-optimization#edge-network-optimization',
    'image-optimization#edge-network-optimization',
  ],
  [
    'next.js/image-optimization#measuring-performance',
    'image-optimization#measuring-performance',
  ],
  [
    'next.js/image-optimization#source-images',
    'image-optimization/limits-and-pricing#source-images',
  ],
  [
    'next.js/image-optimization#limits',
    'image-optimization/limits-and-pricing',
  ],
  [
    'incremental-static-regeneration/overview#not-just-caching',
    'incremental-static-regeneration/overview#isr-vs.-cache-control-headers',
  ],
  [
    'functions/introduction#deploying-serverless-functions',
    'functions/serverless-functions#deploying-serverless-functions',
  ],
  [
    'functions/introduction#example-serverless-function-with-next.js',
    'functions/serverless-functions#deploying-serverless-functions',
  ],
  [
    'functions/introduction#more-examples-and-supported-languages',
    'functions/serverless-functions/quickstart',
  ],
  [
    'functions/introduction#environment-variables',
    'functions/serverless-functions/quickstart',
  ],
  [
    'functions/introduction#path-segments',
    'functions/serverless-functions/quickstart',
  ],
  [
    'functions/introduction#execution-timeout',
    'functions/serverless-functions/runtimes#max-duration',
  ],
  [
    'functions/introduction#timeout-conditions-checklist',
    'functions/serverless-functions#timeout-conditions-checklist',
  ],
  [
    'functions/introduction#error-logs',
    'functions/serverless-functions#error-logs',
  ],
  [
    'functions/introduction#local-development',
    'functions/serverless-functions#local-development',
  ],
  [
    'functions/introduction#using-environment-variables-locally',
    'functions/serverless-functions#using-environment-variables-locally',
  ],
  [
    'functions/introduction#logging-serverless-functions',
    'functions/serverless-functions#logging-serverless-functions',
  ],
  [
    'functions/introduction#next.js-logs',
    'functions/serverless-functions#next.js-logs',
  ],
  [
    'functions/introduction#bundling-serverless-functions',
    'functions/serverless-functions#bundling-serverless-functions',
  ],
  [
    'functions/introduction#advanced-usage',
    'functions/serverless-functions#advanced-usage',
  ],
  [
    'functions/introduction#technical-details',
    'functions/serverless-functions#technical-details',
  ],
  [
    'functions/introduction#cpu-calculation',
    'functions/serverless-functions#cpu-calculation',
  ],
  ['functions/introduction#limits', 'functions/serverless-functions#limits'],
  ['functions/introduction#related', 'functions/serverless-functions#related'],

  // Edge Functions
  [
    'functions/edge-functions#creating-edge-functions',
    'functions/edge-functions/quickstart',
  ],
  [
    'functions/edge-functions#benefits-of-edge-functions',
    'functions/edge-functions#how-edge-functions-work',
  ],
  [
    'functions/edge-functions/regions',
    'functions/edge-functions#edge-function-regions',
  ],
  // Edge Functions API stuff
  [
    'functions/edge-functions/edge-functions-api#edge-function-apis',
    'functions/edge-functions/edge-functions-api#supported-apis',
  ],
  [
    'functions/edge-functions/edge-functions-api#network-apis',
    'functions/edge-functions/edge-functions-api#supported-apis',
  ],
  [
    'functions/edge-functions/edge-functions-api#encoding-apis',
    'functions/edge-functions/edge-functions-api#supported-apis',
  ],
  [
    'functions/edge-functions/edge-functions-api#web-stream-apis',
    'functions/edge-functions/edge-functions-api#supported-apis',
  ],
  [
    'functions/edge-functions/edge-functions-api#web-crypto-apis',
    'functions/edge-functions/edge-functions-api#supported-apis',
  ],
  [
    'functions/edge-functions/edge-functions-api#web-standards-apis',
    'functions/edge-functions/edge-functions-api#supported-apis',
  ],
  [
    'functions/edge-functions/edge-functions-api#v8-primitives',
    'functions/edge-functions/edge-functions-api#supported-apis',
  ],
  [
    'functions/edge-functions/edge-functions-api#the-@vercel/edge-package',
    'functions/edge-functions/vercel-edge-package',
  ],
  [
    'functions/edge-functions/edge-functions-api#geolocation',
    'functions/edge-functions/vercel-edge-package#geolocation',
  ],
  [
    'functions/edge-functions/edge-functions-api#ipaddress',
    'functions/edge-functions/vercel-edge-package#ipaddress',
  ],
  [
    'functions/edge-functions/edge-functions-api#regional-edge-function-execution',
    'functions/edge-functions#edge-function-regions',
  ],
  [
    'functions/edge-functions/limitations#node.js-apis-are-not-available',
    'functions/edge-functions/limitations#most-node.js-apis-are-not-available',
  ],
  [
    'functions/edge-functions/getting-started-with-edge-functions#steps',
    'functions/edge-functions/getting-started-with-edge-functions#create-your-first-edge-function',
  ],
  [
    'functions/edge-functions/getting-started-with-edge-functions#create-your-first-edge-function',
    'functions/edge-functions/getting-started-with-edge-functions#create-an-edge-function-using-next.js',
  ],
  [
    'functions/edge-functions#middleware',
    'functions/regions#unused-serverless-function-region-setting',
  ],
  [
    'functions/edge-functions#middleware-use-cases',
    'functions/regions#unused-serverless-function-region-setting',
  ],
  [
    'functions/edge-functions#how-to-use-middleware',
    'functions/edge-functions/middleware#how-to-use-middleware',
  ],
  ['functions/edge-functions#api', 'functions/edge-functions/middleware-api'],
  [
    'functions/edge-functions#nextfetchevent',
    'functions/edge-functions/middleware-api#nextfetchevent',
  ],
  [
    'functions/edge-functions#nextrequest',
    'functions/edge-functions/middleware-api#nextrequest',
  ],
  [
    'functions/edge-functions#nextresponse',
    'functions/edge-functions/middleware-api#nextresponse',
  ],
  [
    'functions/edge-functions#why-does-redirect()-use-307-and-308',
    'functions/edge-functions/middleware-api#why-does-redirect()-use-307-and-308',
  ],
  [
    'functions/edge-functions#middleware-running-order',
    'functions/edge-functions/middleware#middleware-running-order',
  ],
  [
    'functions/edge-functions#runtime',
    'functions/edge-functions/middleware#runtime',
  ],
  [
    'functions/edge-functions#runtime-apis',
    'functions/edge-functions/middleware-api#runtime-apis',
  ],
  [
    'functions/edge-functions#globals',
    'functions/edge-functions/middleware-api#globals',
  ],
  [
    'functions/edge-functions#base64',
    'functions/edge-functions/middleware-api#base64',
  ],
  [
    'functions/edge-functions#encoding',
    'functions/edge-functions/middleware-api#encoding',
  ],
  [
    'functions/edge-functions#environment',
    'functions/edge-functions/middleware-api#environment',
  ],
  [
    'functions/edge-functions#fetch',
    'functions/edge-functions/middleware-api#fetch',
  ],
  [
    'functions/edge-functions#streams',
    'functions/edge-functions/middleware-api#streams',
  ],
  [
    'functions/edge-functions#timers',
    'functions/edge-functions/middleware-api#timers',
  ],
  [
    'functions/edge-functions#web',
    'functions/edge-functions/middleware-api#web',
  ],
  [
    'functions/edge-functions#crypto',
    'functions/edge-functions/middleware-api#crypto',
  ],
  [
    'functions/edge-functions#logging',
    'functions/edge-functions/middleware-api#logging',
  ],
  [
    'functions/edge-functions#unsupported-apis',
    'functions/edge-functions/middleware-api#unsupported-apis-and-runtime-restrictions',
  ],
  [
    'functions/conceptual-model#versus-processes-and-containers',
    'functions/concepts/conceptual-model#versus-processes-and-containers',
  ],
  [
    'functions/conceptual-model#per-entrypoint-granularity',
    'functions/concepts/conceptual-model#per-entrypoint-granularity',
  ],
  [
    'functions/conceptual-model#lifecycle-and-scalability',
    'functions/concepts/conceptual-model#lifecycle-and-scalability',
  ],
  [
    'functions/conceptual-model#cold-and-hot-boots',
    'functions/concepts/conceptual-model#cold-and-hot-boots',
  ],
  ['functions/edge-caching#what-is-cached', 'edge-network/caching'],
  ['functions/edge-caching#cache-control', 'edge-network/caching'],
  ['functions/edge-caching#recommended-cache-control', 'edge-network/caching'],
  [
    'functions/edge-caching#cache-invalidation-and-purging',
    'edge-network/caching#cache-invalidation',
  ],
  [
    'functions/regions#default-region',
    'functions/serverless-functions/regions#select-a-default-serverless-region',
  ],
  [
    'functions/serverless-functions/regions#default-region',
    'functions/serverless-functions/regions#select-a-default-serverless-region',
  ],
  [
    'functions/regions#restrictions',
    'functions/serverless-functions/regions#select-a-default-serverless-region',
  ],
  [
    'functions/serverless-functions/regions#restrictions',
    'functions/serverless-functions/regions#select-a-default-serverless-region',
  ],
  [
    'functions/regions#configuring-the-region',
    'functions/serverless-functions/regions#select-a-default-serverless-region',
  ],
  [
    'functions/serverless-functions/regions#configuring-the-region',
    'functions/serverless-functions/regions#select-a-default-serverless-region',
  ],
  [
    'functions/regions#multiple-regions',
    'functions/serverless-functions/regions#set-multiple-serverless-regions',
  ],
  [
    'functions/serverless-functions/regions#multiple-regions',
    'functions/serverless-functions/regions#set-multiple-serverless-regions',
  ],
  [
    'functions/regions#unused-serverless-function-region-setting',
    'functions/serverless-functions/regions#unused-serverless-function-region-setting',
  ],
  [
    'functions/supported-languages#supported-languages',
    'functions/serverless-functions/supported-languages#supported-languages',
  ],
  [
    'functions/supported-languages#node.js',
    'functions/serverless-functions/supported-languages#node.js',
  ],
  [
    'functions/supported-languages#using-typescript',
    'functions/serverless-functions/supported-languages#using-typescript',
  ],
  [
    'functions/supported-languages#advanced-node.js-usage',
    'functions/serverless-functions/supported-languages#advanced-node.js-usage',
  ],
  [
    'functions/supported-languages#go',
    'functions/serverless-functions/supported-languages#go',
  ],
  [
    'functions/supported-languages#advanced-go-usage',
    'functions/serverless-functions/supported-languages#advanced-go-usage',
  ],
  [
    'functions/supported-languages#python',
    'functions/serverless-functions/supported-languages#python',
  ],
  [
    'functions/supported-languages#advanced-python-usage',
    'functions/serverless-functions/supported-languages#advanced-python-usage',
  ],
  [
    'functions/supported-languages#ruby',
    'functions/serverless-functions/supported-languages#ruby',
  ],
  [
    'functions/supported-languages#related',
    'functions/serverless-functions/supported-languages#related',
  ],
  [
    'functions/edge-functions#technical-details',
    'functions/edge-functions/edge-functions-limitations',
  ],
  ['edge-network/x-vercel-cache', 'edge-network/caching#x-vercel-cache'],
  ['edge-network/x-vercel-cache#miss', 'edge-network/caching#miss'],
  ['edge-network/x-vercel-cache#hit', 'edge-network/caching#hit'],
  ['edge-network/x-vercel-cache#stale', 'edge-network/caching#stale'],
  ['edge-network/x-vercel-cache#bypass', 'edge-network/caching#bypass'],
  ['edge-network/x-vercel-cache#prerender', 'edge-network/caching#prerender'],
  [
    'edge-network/x-vercel-cache#revalidated',
    'edge-network/caching#revalidated',
  ],
  [
    'edge-network/caching#server',
    'edge-network/caching#using-vercel-functions',
  ],
  [
    'payments-and-billing/pro#how-long-after-my-trial-will-i-be-billed',
    'payments-and-billing/free-trials#how-long-after-my-trial-will-i-be-billed',
  ],
  [
    'payments-and-billing/pro#what-happens-if-i-resume-using-vercel-several-months-after-my-trial-ends',
    'payments-and-billing/free-trials#what-happens-if-i-resume-using-vercel-several-months-after-my-trial-ends',
  ],
  [
    'payments-and-billing/pro#how-do-team-member-add/removal-(mid-term)-costs-work',
    'payments-and-billing/pro#how-do-team-member-add-removal-mid-term-costs-work',
  ],
  // Team Roles docs (a.k.a. RBAC) redirects
  ['teams/members#account-roles', 'teams-and-accounts/team-members-and-role'],
  [
    'teams/members#pro-team-account-roles',
    'teams-and-accounts/team-members-and-roles#pro-team-roles',
  ],
  [
    'teams/members#enterprise-team-account-roles',
    'teams-and-accounts/team-members-and-roles#enterprise-team-roles',
  ],
  // Team Membership docs redirects
  ['teams/team-membership', 'teams-and-accounts'],
  // Git redirects
  [
    'deployments/git/monorepos#using-monorepos-with-vercel-dashboard',
    'projects/monorepos#using-monorepos-with-vercel-dashboard',
  ],
  [
    'deployments/git/monorepos#using-monorepos-with-vercel-dashboard',
    'projects/monorepos#using-monorepos-with-vercel-dashboard',
  ],
  [
    'deployments/git/monorepos#ignoring-the-build-step',
    'projects/monorepos#ignoring-the-build-step',
  ],
  ['deployments/git/monorepos#turborepo', 'projects/monorepos/turborepo'],
  [
    'deployments/git/monorepos#setup-turborepo',
    'projects/monorepos/turborepo#deploy-turborepo-to-vercel',
  ],
  [
    'deployments/git/monorepos#step-0:-ensure-you-are-caching-environment-variables-(and-files-outside-of-packages-and-apps)-correctly',
    'projects/monorepos/turborepo#step-0:-cache-environment-variables',
  ],
  [
    'deployments/git/monorepos#step-1:-import-your-project',
    'projects/monorepos/turborepo#step-1:-import-your-project',
  ],
  [
    'deployments/git/monorepos#step-2:-set-the-build-command',
    'projects/monorepos/turborepo#step-2:-set-the-build-command',
  ],
  [
    'deployments/git/monorepos#step-3:-set-the-root-directory',
    'projects/monorepos/turborepo#step-3:-set-the-root-directory',
  ],
  [
    'deployments/git/monorepos#step-3.5:-optional-settings-if-using-npm-workspaces',
    'projects/monorepos/turborepo#step-3.5:-optional-settings-if-using-npm-workspaces',
  ],
  [
    'deployments/git/monorepos#step-4:-link-your-project',
    'projects/monorepos/turborepo#step-4:-link-your-project',
  ],
  [
    'deployments/git/monorepos#step-5:-test-the-caching',
    'projects/monorepos/turborepo#step-5:-test-the-caching',
  ],
  ['deployments/git/monorepos#monorepo-faq', 'projects/monorepos#monorepo-faq'],
  // Vercel for GitHub redirects
  [
    'git/vercel-for-github#deploying-a-github-repository',
    'deployments/git/vercel-for-github#deploying-a-github-repository',
  ],
  [
    'git/vercel-for-github#changing-the-github-repository-of-a-project',
    'deployments/git/vercel-for-github#changing-the-github-repository-of-a-project',
  ],
  [
    'git/vercel-for-github#a-deployment-for-each-push',
    'deployments/git/vercel-for-github#a-deployment-for-each-push',
  ],
  [
    'git/vercel-for-github#updating-the-production-domain',
    'deployments/git/vercel-for-github#updating-the-production-domain',
  ],
  [
    'git/vercel-for-github#preview-urls-for-the-latest-changes-for-each-pull-request',
    'deployments/git/vercel-for-github#preview-urls-for-the-latest-changes-for-each-pull-request',
  ],
  [
    'git/vercel-for-github#deployment-authorizations-for-forks',
    'deployments/git/vercel-for-github#deployment-authorizations-for-forks',
  ],
  [
    'git/vercel-for-github#configuring-for-github',
    'deployments/git/vercel-for-github#configuring-for-github',
  ],
  [
    'git/vercel-for-github#repository-permissions',
    'deployments/git/vercel-for-github#repository-permissions',
  ],
  [
    'git/vercel-for-github#organization-permissions',
    'deployments/git/vercel-for-github#organization-permissions',
  ],
  [
    'git/vercel-for-github#user-permissions',
    'deployments/git/vercel-for-github#user-permissions',
  ],
  [
    'git/vercel-for-github#missing-git-repository',
    'deployments/git/vercel-for-github#missing-git-repository',
  ],
  [
    'git/vercel-for-github#silence-github-comments',
    'deployments/git/vercel-for-github#silence-github-comments',
  ],
  [
    'git/vercel-for-github#using-github-actions',
    'deployments/git/vercel-for-github#using-github-actions',
  ],
  // Vercel for GitLab redirects
  [
    'git/vercel-for-gitlab#supported-gitlab-products',
    'deployments/git/vercel-for-gitlab#supported-gitlab-products',
  ],
  [
    'git/vercel-for-gitlab#deploying-a-gitlab-repository',
    'deployments/git/vercel-for-gitlab#deploying-a-gitlab-repository',
  ],
  [
    'git/vercel-for-gitlab#changing-the-gitlab-repository-of-a-project',
    'deployments/git/vercel-for-gitlab#changing-the-gitlab-repository-of-a-project',
  ],
  [
    'git/vercel-for-gitlab#a-deployment-for-each-push',
    'deployments/git/vercel-for-gitlab#a-deployment-for-each-push',
  ],
  [
    'git/vercel-for-gitlab#updating-the-production-domain',
    'deployments/git/vercel-for-gitlab#updating-the-production-domain',
  ],
  [
    'git/vercel-for-gitlab#preview-urls-for-each-merge-request',
    'deployments/git/vercel-for-gitlab#preview-urls-for-each-merge-request',
  ],
  [
    'git/vercel-for-gitlab#missing-git-repository',
    'deployments/git/vercel-for-gitlab#missing-git-repository',
  ],
  [
    'git/vercel-for-gitlab#using-gitlab-pipelines',
    'deployments/git/vercel-for-gitlab#using-gitlab-pipelines',
  ],
  // Vercel for Bitbucket redirects
  [
    'git/vercel-for-bitbucket#supported-bitbucket-products',
    'deployments/git/vercel-for-bitbucket#supported-bitbucket-products',
  ],
  [
    'git/vercel-for-bitbucket#deploying-a-bitbucket-repository',
    'deployments/git/vercel-for-bitbucket#deploying-a-bitbucket-repository',
  ],
  [
    'git/vercel-for-bitbucket#changing-the-bitbucket-repository-of-a-project',
    'deployments/git/vercel-for-bitbucket#changing-the-bitbucket-repository-of-a-project',
  ],
  [
    'git/vercel-for-bitbucket#a-deployment-for-each-push',
    'deployments/git/vercel-for-bitbucket#a-deployment-for-each-push',
  ],
  [
    'git/vercel-for-bitbucket#updating-the-production-domain',
    'deployments/git/vercel-for-bitbucket#updating-the-production-domain',
  ],
  [
    'git/vercel-for-bitbucket#preview-urls-for-each-pull-request',
    'deployments/git/vercel-for-bitbucket#preview-urls-for-each-pull-request',
  ],
  [
    'git/vercel-for-bitbucket#repository-permissions',
    'deployments/git/vercel-for-bitbucket#repository-permissions',
  ],
  [
    'git/vercel-for-bitbucket#organization-permissions',
    'deployments/git/vercel-for-bitbucket#organization-permissions',
  ],
  [
    'git/vercel-for-bitbucket#user-permissions',
    'deployments/git/vercel-for-bitbucket#user-permissions',
  ],
  [
    'git/vercel-for-bitbucket#missing-git-repository',
    'deployments/git/vercel-for-bitbucket#missing-git-repository',
  ],
  [
    'git/vercel-for-bitbucket#using-bitbucket-pipelines',
    'deployments/git/vercel-for-bitbucket#using-bitbucket-pipelines',
  ],
  // Deploy Hooks redirects
  [
    'git/deploy-hooks#creating-a-deploy-hook',
    'deployments/deploy-hooks#creating-a-deploy-hook',
  ],
  [
    'git/deploy-hooks#triggering-a-deploy-hook',
    'deployments/deploy-hooks#triggering-a-deploy-hook',
  ],
  [
    'git/deploy-hooks#example-request',
    'deployments/deploy-hooks#example-request',
  ],
  [
    'git/deploy-hooks#example-response',
    'deployments/deploy-hooks#example-response',
  ],
  ['git/deploy-hooks#security', 'deployments/deploy-hooks#security'],
  ['git/deploy-hooks#build-cache', 'deployments/deploy-hooks#build-cache'],
  [
    'git/deploy-hooks#other-optimizations',
    'deployments/deploy-hooks#other-optimizations',
  ],
  ['git/deploy-hooks#limits', 'deployments/deploy-hooks#limits'],
  [
    'deployments/remote-caching#remote-caching-with-turborepo-and-vercel',
    'projects/monorepos/remote-caching#vercel-remote-caching',
  ],
  [
    'deployments/remote-caching#local-development',
    'projects/monorepos/remote-caching#authenticate-with-vercel',
  ],
  [
    'deployments/remote-caching#during-vercel-build',
    'projects/monorepos/remote-caching#use-remote-caching-during-vercel-build',
  ],
  [
    'deployments/remote-caching#from-external-ci/cd',
    'projects/monorepos/remote-caching#use-remote-caching-from-external-ci/cd',
  ],
  [
    'deployments/remote-caching#usage-limits',
    'projects/monorepos/remote-caching#usage',
  ],
  //Deployments redirects
  ['deployments/overview#logs', 'deployments/logs'],
  ['deployments/overview#build-time', 'deployments/logs#build-logs'],
  ['deployments/overview#edge-network', 'deployments/logs#function-logs'],
  [
    'deployments/overview#special-paths',
    'deployments/build-features#special-paths',
  ],
  [
    'deployments/overview#source-view',
    'deployments/build-features#source-view',
  ],
  ['deployments/overview#logs-view', 'deployments/build-features#logs-view'],
  [
    'deployments/overview#security-considerations',
    'deployments/build-features#security-considerations',
  ],
  ['deployments/build-step#concurrent-builds', 'deployments/concurrent-builds'],
  [
    'deployments/build-step#ignored-files-and-folders',
    'deployments/build-features#ignored-files-and-folders',
  ],
  [
    'deployments/configure-a-build#git-submodules',
    'deployments/build-features#git-submodules',
  ],
  [
    'deployments/build-step#caching',
    'deployments/troubleshoot-a-build#what-is-cached',
  ],
  [
    'deployments/configure-a-build#caching',
    'deployments/troubleshoot-a-build#what-is-cached',
  ],
  [
    'deployments/build-step#technical-details',
    'deployments/troubleshoot-a-build',
  ],
  [
    'deployments/build-step#environment-variables',
    'deployments/environments#environment-variables',
  ],
  ['deployments/build-step#git-submodules', 'deployments/build-features'],
  ['deployments/troubleshoot-a-build#build-image', 'deployments/build-image'],
  [
    'deployments/troubleshoot-a-build#pre-installed-packages',
    'deployments/build-image#pre-installed-packages',
  ],
  [
    'deployments/troubleshoot-a-build#running-the-build-image-locally',
    'deployments/build-image#running-the-build-image-locally',
  ],
  [
    'deployments/troubleshoot-a-build#installing-php-in-the-build-image',
    'deployments/build-image#installing-php-in-the-build-image',
  ],
  [
    'deployments/troubleshoot-a-build#installing-rust-in-the-build-image',
    'deployments/build-image#installing-rust-in-the-build-image',
  ],
  [
    'deployments/troubleshoot-a-build#installing-go-in-the-build-image',
    'deployments/build-image#installing-go-in-the-build-image',
  ],
  [
    'deployments/troubleshoot-a-build#installing-something-else-in-the-build-image',
    'deployments/build-image#installing-something-else-in-the-build-image',
  ],
  //domains redirects
  [
    'projects/domains#buying-a-domain-name',
    'projects/domains/working-with-domains#buying-a-domain-name',
  ],
  [
    'projects/domains#buying-a-domain-through-vercel',
    'projects/domains/working-with-domains#buying-a-domain-through-vercel',
  ],
  [
    'projects/domains#buying-a-domain-through-a-third-party',
    'projects/domains/working-with-domains#buying-a-domain-through-a-third-party',
  ],
  [
    'projects/domains#domain-ownership-and-project-assignment',
    'projects/domains/working-with-domains#domain-ownership-and-project-assignment',
  ],
  [
    'projects/domains#subdomains,-wildcard-domains,-and-apex-domains',
    'projects/domains/working-with-domains#subdomains,-wildcard-domains,-and-apex-domains',
  ],
  [
    'projects/domains#using-email-with-domains',
    'projects/domains/working-with-domains#using-email-with-domains',
  ],
  ['projects/domains#dns-configuration', 'projects/domains/working-with-dns'],
  [
    'projects/domains#dns-records',
    'projects/domains/working-with-dns#dns-records',
  ],
  [
    'projects/domains#dns-propagation',
    'projects/domains/working-with-dns#dns-propagation',
  ],
  [
    'projects/domains#dns-best-practises',
    'projects/domains/working-with-dns#dns-best-practises',
  ],
  [
    'projects/domains/working-with-dns#dns-best-practises',
    'projects/domains/working-with-dns#dns-best-practices',
  ],
  ['projects/domains#nameservers', 'projects/domains/working-with-nameservers'],
  [
    "projects/domains#what-are-the-benefits-of-using-vercel's-nameserver",
    'projects/domains/working-with-nameservers#benefits-of-using-vercel-nameservers',
  ],
  ['projects/domains#ssl-certification', 'projects/domains/working-with-ssl'],
  [
    'deployments/generated-urls#how-to-access-these-urls',
    'deployments/generated-urls#how-to-access-generated-urls',
  ],
];
