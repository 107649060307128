import { generateRedirects } from '../utils';

export function CLIRedirects(): void {
  generateRedirects({
    location: { pathname: location.pathname, hash: location.hash },
    rootPrefix: '/docs/cli',
    sections: [],
    exceptions: [
      ['cli#introduction/installing-the-cli', 'cli#installing-vercel-cli'],
      ['cli#introduction/basic-usage', 'cli/deploy#usage'],
      [
        'cli#introduction/standard-output-usage',
        'cli/deploy#standard-output-usage',
      ],
      ['cli#project-linking', 'cli/project-linking'],
      ['cli#introduction/project-linking', 'cli/project-linking'],
      ['cli#introduction/framework-detection', 'cli#framework-detection'],
      ['cli#introduction/unique-options', 'cli/deploy#unique-options'],
      ['cli#standard-output-usage', 'cli/deploy#usage'],
      ['cli#basic-usage', 'cli/deploy#usage'],
      ['cli#unique-options', 'cli/deploy#unique-options'],
      ['cli#introduction/unique-options/build-env', 'cli/deploy#build-env'],
      [
        'cli#introduction/unique-options/build-env/usage-example',
        'cli/deploy#build-env',
      ],
      ['cli#introduction/unique-options/yes', 'cli#yes'],
      ['cli#introduction/unique-options/yes/usage-example', 'cli#yes'],
      ['cli#introduction/unique-options/env', 'cli#env'],
      ['cli#introduction/unique-options/env/usage-example', 'cli#env'],
      ['cli#introduction/unique-options/name', 'cli#name'],
      ['cli#introduction/unique-options/name/usage-example', 'cli#name'],
      ['cli#introduction/unique-options/prod', 'cli#prod'],
      ['cli#introduction/unique-options/prod/usage-example', 'cli#prod'],
      ['cli#introduction/unique-options/public', 'cli#public'],
      ['cli#introduction/unique-options/public/usage-example', 'cli#public'],
      ['cli#introduction/unique-options/regions', 'cli#regions'],
      ['cli#introduction/unique-options/regions/usage-example', 'cli#regions'],
      ['cli#introduction/unique-options/version', 'cli#version'],
      ['cli#introduction/unique-options/version/usage-example', 'cli#version'],
      ['cli#build-env', 'cli/deploy#build-env'],
      ['cli#yes', 'cli/deploy#yes'],
      ['cli#env', 'cli/deploy#env'],
      ['cli#name', 'cli/deploy#name'],
      ['cli#prod', 'cli/deploy#prod'],
      ['cli#public', 'cli/deploy#public'],
      ['cli#regions', 'cli/deploy#regions'],
      ['cli#version', 'cli/deploy#checking-the-version'],
      ['cli#commands/alias', 'cli/alias'],
      ['cli#commands/alias/usage', 'cli/alias#usage'],
      ['cli#commands/alias/global-options', 'cli/alias#global-options'],
      ['cli#commands/alias/unique-options/yes', 'cli/alias#yes'],
      ['cli#commands/bisect', 'cli/bisect'],
      ['cli#commands/bisect/basic-usage', 'cli/bisect#usage'],
      ['cli#commands/bisect/global-options', 'cli/bisect#global-options'],
      ['cli#commands/bisect/unique-options', 'cli/bisect#unique-options'],
      ['cli#commands/bisect/unique-options/good', 'cli/bisect#good'],
      ['cli#commands/bisect/unique-options/bad', 'cli/bisect#bad'],
      ['cli#commands/bisect/unique-options/path', 'cli/bisect#path'],
      ['cli#commands/bisect/unique-options/open', 'cli/bisect#open'],
      ['cli#commands/bisect/unique-options/run', 'cli/bisect#run'],
      ['cli#commands/build', 'cli/build'],
      ['cli#commands/build/basic-usage', 'cli/build#usage'],
      ['cli#commands/build/global-options', 'cli/build#global-options'],
      ['cli#commands/build/unique-options', 'cli/build#unique-options'],
      ['cli#commands/build/unique-options/production', 'cli/build#production'],
      ['cli#commands/build/unique-options/yes', 'cli/build#yes'],
      ['cli#commands/certs', 'cli/certs'],
      ['cli#commands/certs/basic-usage', 'cli/certs#usage'],
      ['cli#commands/certs/extended-usage', 'cli/certs#extended-usage'],
      ['cli#commands/certs/global-options', 'cli/certs#global-options'],
      ['cli#commands/certs/unique-options', 'cli/certs#unique-options'],
      [
        'cli#commands/certs/unique-options/challenge-only',
        'cli/certs#challenge-only',
      ],
      ['cli#commands/dev', 'cli/dev'],
      [
        'cli#commands/dev/when-to-use-this-command',
        'cli/dev#when-to-use-this-command',
      ],
      ['cli#commands/dev/basic-usage', 'cli/dev#usage'],
      ['cli#commands/dev/global-options', 'cli/dev#global-options'],
      ['cli#commands/dev/unique-options', 'cli/dev#unique-options'],
      ['cli#commands/dev/unique-options/listen', 'cli/dev#listen'],
      ['cli#commands/dev/unique-options/yes', 'cli/dev#yes'],
      ['cli#commands/dns', 'cli/dns'],
      ['cli#commands/dns/basic-usage', 'cli/dns#usage'],
      ['cli#commands/dns/extended-usage', 'cli/dns#extended-usage'],
      ['cli#commands/dns/global-options', 'cli/dns#global-options'],
      ['cli#commands/domains', 'cli/domains'],
      ['cli#commands/domains/basic-usage', 'cli/domains#usage'],
      ['cli#commands/domains/extended-usage', 'cli/domains#extended-usage'],
      ['cli#commands/domains/global-options', 'cli/domains#global-options'],
      ['cli#commands/domains/unique-options', 'cli/domains#unique-options'],
      ['li#commands/domains/unique-options/yes', 'cli/domains#yes'],
      ['cli#commands/env', 'cli/env'],
      ['cli#commands/env/basic-usage', 'cli/env#usage'],
      ['cli#commands/env/extended-usage', 'cli/env#extended-usage'],
      ['cli#commands/env/global-options', 'cli/env#global-options'],
      ['cli#commands/env/unique-options', 'cli/env#unique-options'],
      ['cli#commands/env/unique-options/yes', 'cli/env#yes'],
      ['cli#commands/git', 'cli/git'],
      ['cli#commands/git/basic-usage', 'cli/git#usage'],
      ['cli#commands/git/global-options', 'cli/git#global-options'],
      ['cli#commands/git/unique-options', 'cli/git#unique-options'],
      ['cli#commands/git/unique-options/yes', 'cli/git#yes'],
      ['cli#commands/help', 'cli/help'],
      ['cli#commands/help/basic-usage', 'cli/help#usage'],
      ['cli#commands/help/extended-usage', 'cli/help#extended-usage'],
      ['cli#commands/init', 'cli/init'],
      ['cli#commands/init/basic-usage', 'cli/init#usage'],
      ['cli#commands/init/extended-usage', 'cli/init#extended-usage'],
      ['cli#commands/init/global-options', 'cli/init#global-options'],
      ['cli#commands/inspect', 'cli/inspect'],
      ['cli#commands/inspect/basic-usage', 'cli/inspect#usage'],
      ['cli#commands/inspect/extended-usage', 'cli/inspect#extended-usage'],
      ['cli#commands/inspect/global-options', 'cli/inspect#global-options'],
      ['cli#commands/link', 'cli/link'],
      ['cli#commands/link/basic-usage', 'cli/link#usage'],
      ['cli#commands/link/extended-usage', 'cli/link#extended-usage'],
      ['cli#commands/link/global-options', 'cli/link#global-options'],
      ['cli#commands/link/unique-options', 'cli/link#unique-options'],
      ['cli#commands/link/unique-options/yes', 'cli/link#yes'],
      ['cli#commands/list', 'cli/list'],
      ['cli#commands/list/basic-usage', 'cli/list#usage'],
      ['cli#commands/list/extended-usage', 'cli/list#extended-usage'],
      ['cli#commands/list/global-options', 'cli/list#global-options'],
      ['cli#commands/list/unique-options', 'cli/list#unique-options'],
      ['cli#commands/list/unique-options/meta', 'cli/list#meta'],
      ['cli#commands/list/unique-options/yes', 'cli/list#yes'],
      ['cli#commands/login', 'cli/login'],
      ['cli#commands/login/basic-usage', 'cli/login#usage'],
      ['cli#commands/login/unique-options', 'cli/login#unique-options'],
      ['cli#commands/login/unique-options/git-hub', 'cli/login#github'],
      ['cli#commands/login/unique-options/git-lab', 'cli/login#gitlab'],
      ['cli#commands/login/unique-options/bitbucket', 'cli/login#bitbucket'],
      [
        'cli#commands/login/unique-options/out-of-band-mode',
        'cli/login#out-of-band-mode',
      ],
      ['cli#commands/logout', 'cli/logout'],
      ['cli#commands/logout/basic-usage', 'cli/logout#usage'],
      ['cli#commands/logs', 'cli/logs'],
      ['cli#commands/logs/basic-usage', 'cli/logs#usage'],
      ['cli#commands/logs/global-options', 'cli/logs#global-options'],
      ['cli#commands/logs/unique-options', 'cli/logs#unique-options'],
      ['cli#commands/logs/unique-options/follow', 'cli/logs#follow'],
      ['cli#commands/logs/unique-options/number', 'cli/logs#number'],
      ['cli#commands/logs/unique-options/output', 'cli/logs#output'],
      ['cli#commands/logs/unique-options/since', 'cli/logs#since'],
      ['cli#commands/logs/unique-options/query', 'cli/logs#query'],
      ['cli#commands/logs/unique-options/until', 'cli/logs#until'],
      ['cli#commands/project', 'cli/project'],
      ['cli#commands/project/basic-usage', 'cli/project#usage'],
      ['cli#commands/project/global-options', 'cli/project#global-options'],
      ['cli#commands/pull', 'cli/pull'],
      ['cli#commands/pull/basic-usage', 'cli/pull#usage'],
      ['cli#commands/pull/global-options', 'cli/pull#global-options'],
      ['cli#commands/pull/unique-options', 'cli/pull#unique-options'],
      ['cli#commands/pull/unique-options/yes', 'cli/pull#yes'],
      ['cli#commands/remove', 'cli/remove'],
      ['cli#commands/remove/basic-usage', 'cli/remove#usage'],
      ['cli#commands/remove/extended-usage', 'cli/remove#extended-usage'],
      ['cli#commands/remove/global-options', 'cli/remove#global-options'],
      ['cli#commands/remove/unique-options', 'cli/remove#unique-options'],
      ['cli#commands/remove/unique-options/safe', 'cli/remove#safe'],
      ['cli#commands/remove/unique-options/yes', 'cli/remove#yes'],
      ['cli#commands/secrets', 'cli/secrets'],
      ['cli#commands/secrets/basic-usage', 'cli/secrets#usage'],
      ['cli#commands/secrets/extended-usage', 'cli/secrets#extended-usage'],
      ['cli#commands/secrets/global-options', 'cli/secrets#global-options'],
      ['cli#commands/switch', 'cli/switch'],
      ['cli#commands/switch/basic-usage', 'cli/switch#usage'],
      ['cli#commands/switch/extended-usage', 'cli/switch#extended-usage'],
      ['cli#commands/switch/global-options', 'cli/switch#global-options'],
      ['cli#commands/teams', 'cli/teams'],
      ['cli#commands/teams/basic-usage', 'cli/teams#usage'],
      ['cli#commands/teams/extended-usage', 'cli/teams#extended-usage'],
      ['cli#commands/teams/global-options', 'cli/teams#global-options'],
      ['cli#commands/whoami', 'cli/whoami'],
      ['cli#commands/whoami/basic-usage', 'cli/whoami#usage'],
      ['cli#commands/whoami/global-options', 'cli/whoami#global-options'],
      ['cli#project-configuration', 'projects/project-configuration'],
      [
        'cli#project-configuration/functions',
        'projects/project-configuration#functions',
      ],
      [
        'cli#project-configuration/regions',
        'projects/project-configuration#regions',
      ],
      [
        'cli#project-configuration/public',
        'projects/project-configuration#public',
      ],
      [
        'cli#project-configuration/clean-urls',
        'projects/project-configuration#cleanurls',
      ],
      [
        'cli#project-configuration/trailing-slash',
        'projects/project-configuration#trailingslash',
      ],
      [
        'cli#project-configuration/trailing-slash/false',
        'projects/project-configuration#false',
      ],
      [
        'cli#project-configuration/trailing-slash/true',
        'projects/project-configuration#true',
      ],
      [
        'cli#project-configuration/trailing-slash/undefined',
        'projects/project-configuration#undefined',
      ],
      [
        'cli#project-configuration/redirects',
        'projects/project-configuration#redirects',
      ],
      [
        'cli#project-configuration/rewrites',
        'projects/project-configuration#rewrites',
      ],
      [
        'cli#project-configuration/headers',
        'projects/project-configuration#headers',
      ],
      ['cli#legacy', 'projects/project-configuration#legacy'],
      ['cli#legacy/name', 'projects/project-configuration#name'],
      ['cli#legacy/version', 'projects/project-configuration#version'],
      ['cli#legacy/alias', 'projects/project-configuration#alias'],
      ['cli#legacy/scope', 'projects/project-configuration#scope'],
      ['cli#legacy/env', 'projects/project-configuration#env'],
      ['cli#legacy/build-env', 'projects/project-configuration#build-env'],
      ['cli#legacy/builds', 'projects/project-configuration#builds'],
      ['cli#legacy/routes', 'projects/project-configuration#routes'],
      [
        'cli#legacy/routes/upgrading',
        'projects/project-configuration#upgrading-legacy-routes',
      ],
      [
        'cli#git-configuration',
        'projects/project-configuration/git-configuration',
      ],
      [
        'cli#git-configuration/github-enabled',
        'projects/project-configuration/git-configuration#git.deploymentenabled',
      ],
      [
        'cli#git-configuration/github-auto-alias',
        'projects/project-configuration/git-configuration#github.autoalias',
      ],
      [
        'cli#git-configuration/github-silent',
        'projects/project-configuration/git-configuration#github.silent',
      ],
      [
        'cli#git-configuration/github-auto-job-cancelation',
        'projects/project-configuration/git-configuration#github.autojobcancelation',
      ],
      [
        'cli#global-configuration',
        'projects/project-configuration/global-configuration',
      ],
      [
        'cli#global-configuration/config-json',
        'projects/project-configuration/global-configuration#config.json',
      ],
      [
        'cli#global-configuration/config-json/current-team',
        'projects/project-configuration/global-configuration#currentteam',
      ],
      [
        'cli#global-configuration/config-json/collect-metrics',
        'projects/project-configuration/global-configuration#collectmetrics',
      ],
      [
        'cli#global-configuration/auth-json',
        'project-configuration/global-configuration#auth.json',
      ],
      ['cli#options/unique-options', 'cli/deploy#unique-options'],
      ['cli#options/global-options/token', 'cli/global-options#token'],
      ['cli#options/global-options/scope', 'cli/global-options#scope'],
      [
        'cli#options/global-options/local-config',
        'cli/global-options#local-config',
      ],
      ['cli#options/global-options/help', 'cli/global-options#help'],
      [
        'cli#options/global-options/global-config',
        'cli/global-options#global-config',
      ],
      ['cli#options/global-options/force', 'cli/global-options#force'],
      ['cli#options/global-options/debug', 'cli/global-options#debug'],
      ['cli#options', 'cli/global-options'],
      ['cli#global-options', 'cli/global-options'],
      ['cli#token', 'cli/global-options#token'],
      ['cli#scope', 'cli/global-options#scope'],
      ['cli#local-config', 'cli/global-options#local-config'],
      ['cli#help', 'cli/global-options#help'],
      ['cli#global-config', 'cli/global-options#global-config'],
      ['cli#force', 'cli/global-options#force'],
      ['cli#debug', 'cli/global-options#debug'],
      ['cli#global-options', 'cli/global-options#global-options'],
      ['cli#introduction/vercel-cli-reference', 'cli#installing-vercel-cli'],
      ['cli#options/unique-options', 'cli#options'],
      ['cli/deploy#no-wait', 'cli/deploy#no-wait'],
      ['cli/deploy#with-cache', 'cli/deploy#with-cache'],
    ],
  });
}
