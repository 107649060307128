export const generalList = [
  // The following redirects fix hashes from
  // team-and-accounts/security-and-compliance
  // and teams/security-and-compliance, both of which
  // are redirected to `security` before these redirects
  // are processed.
  ['security#saml-single-sign-on', 'security/saml'],
  ['security#configuring-saml-sso', 'security/saml#configuring-saml-sso'],
  [
    'workflow-collaboration/comments/managing-comments#inspect-comments',
    'workflow-collaboration/comments/managing-comments#troubleshooting-comments',
  ],
  // Vercel Toolbar
  [
    'workflow-collaboration/comments/in-production-and-localhost#prerequisites',
    'workflow-collaboration/vercel-toolbar/in-production-and-localhost',
  ],
  [
    'workflow-collaboration/comments/in-production-and-localhost#using-@vercel/toolbar-in-production',
    'workflow-collaboration/vercel-toolbar/in-production-and-localhost/add-to-production',
  ],
  [
    'workflow-collaboration/comments/in-production-and-localhost#using-@vercel/toolbar-in-localhost',
    'workflow-collaboration/vercel-toolbar/in-production-and-localhost/add-to-localhost',
  ],
  [
    'deployments/overview#vercel-toolbar',
    'workflow-collaboration/vercel-toolbar',
  ],
  [
    'deployments/overview#using-the-command-menu',
    'workflow-collaboration/vercel-toolbar#using-the-command-menu',
  ],
  [
    'deployments/overview#using-your-own-cmd+k-feature',
    'workflow-collaboration/vercel-toolbar#using-your-own-cmd+k-feature',
  ],
  [
    'deployments/overview#sharing-deployments',
    'workflow-collaboration/vercel-toolbar#sharing-deployments',
  ],
  [
    'deployments/preview-deployments#using-your-own-cmd+k-feature',
    'deployments#using-your-own-cmd+k-feature',
  ],
  [
    'deployments/preview-deployments#cmd+k-navigation',
    'workflow-collaboration/vercel-toolbar#using-the-command-menu',
  ],
  [
    'workflow-collaboration/vercel-toolbar#layout-shift-tool',
    'workflow-collaboration/layout-shift-tool',
  ],
  [
    'security#authenticating-with-saml-sso',
    'security/saml#authenticating-with-saml-sso',
  ],
  [
    'security#configuring-directory-sync',
    'security/directory-sync#configuring-directory-sync',
  ],
  [
    'security#customizing-the-login-page',
    'security/saml#customizing-the-login-page',
  ],
  ['security#enforcing-saml', 'security/saml#enforcing-saml'],
  ['security#directory-sync', 'security/directory-sync'],
  ['security#pci', 'security#pci-dss'],
  //Redirects for Vercel WAF ship
  ['security/vercel-firewall#ip-blocking', 'security/vercel-waf/ip-blocking'],
  ['security/vercel-firewall#tls-fingerprints', 'security/tls-fingerprints'],
  ['security/firewall-rules#ip-blocking', 'security/vercel-waf/ip-blocking'],
  [
    'security/firewall-rules#how-to-add-an-ip-block-rule',
    'security/vercel-waf/ip-blocking#how-to-add-an-ip-block-rule',
  ],
  ['security/firewall-rules#limits', 'security/vercel-waf#limits'],
  ['security/firewall-rules#tls-fingerprints', 'security/tls-fingerprints'],
  [
    'security/firewall-rules#tls-fingerprinting',
    'security/tls-fingerprints#tls-fingerprinting',
  ],
  [
    'security/firewall-rules#why-track-tls-fingerprints',
    'security/tls-fingerprints#why-track-tls-fingerprints',
  ],
  ['security/firewall-rules#ja4', 'security/tls-fingerprints#ja4'],
  ['security/firewall-rules#ja3', 'security/tls-fingerprints#ja3'],
  [
    'security/firewall-rules#request-headers',
    'security/tls-fingerprints#request-headers',
  ],
  [
    'security/firewall-rules#x-vercel-ja4-digest-preferred',
    'security/tls-fingerprints#x-vercel-ja4-digest-preferred',
  ],
  [
    'security/firewall-rules#x-vercel-ja3-digest',
    'security/tls-fingerprints#x-vercel-ja3-digest',
  ],
  [
    'functions/edge-middleware/middleware-api#ipaddress',
    'functions/edge-middleware/middleware-api#ip-address',
  ],
  [
    'functions/edge-middleware/middleware-api#rewrite',
    'functions/edge-middleware/middleware-api#rewrites',
  ],
  [
    'functions/edge-middleware/middleware-api#next',
    'functions/edge-middleware/middleware-api#continuing-the-middleware-chain',
  ],
  [
    'security#supported-providers',
    'security/directory-sync#supported-providers',
  ],
  [
    'security/saml#supported-providers',
    'security/directory-sync#supported-providers',
  ],
  ['security#ddos-mitigation', 'security/ddos-mitigation'],
  ['security#deployment-retention', 'security/deployment-retention'],
  [
    'storage/edge-config/edge-config-integrations#statsig',
    'storage/edge-config/integrations/statsig-edge-config',
  ],
  [
    'storage/edge-config/edge-config-integrations#launchdarkly',
    'storage/edge-config/integrations/launchdarkly-edge-config',
  ],
  [
    'storage/edge-config/edge-config-integrations#hypertune',
    'storage/edge-config/integrations/hypertune-edge-config',
  ],
  [
    'functions/edge-functions/vercel-edge-package#ipaddress',
    'functions/edge-functions/edge-functions-api#ipaddress()',
  ],
  [
    'functions/edge-functions/vercel-edge-package#requestcontext',
    'functions/edge-functions/edge-functions-api#requestcontext()',
  ],
  [
    'functions/edge-functions/vercel-edge-package#geolocation',
    'functions/edge-functions/edge-functions-api#geolocation()',
  ],
  [
    'functions/edge-functions/edge-functions-api#rewrite',
    'functions/edge-middleware/middleware-api#rewrite()',
  ],
  [
    'functions/edge-functions/edge-functions-api#next',
    'functions/edge-middleware/middleware-api#next()',
  ],
  [
    'storage/edge-config/integrations#statsig',
    'storage/edge-config/integrations/statsig-edge-config',
  ],
  [
    'storage/edge-config/integrations#launchdarkly',
    'storage/edge-config/integrations/launchdarkly-edge-config',
  ],
  [
    'storage/edge-config/integrations#hypertune',
    'storage/edge-config/integrations/hypertune-edge-config',
  ],
  // Deployment protection
  [
    'security/deployment-protection#features',
    'security/deployment-protection#understanding-deployment-protection-by-environment',
  ],
  [
    'security/deployment-protection#security',
    'security/deployment-protection/methods-to-protect-deployments/vercel-authentication#vercel-authentication-security-considerations',
  ],
  [
    'security/deployment-protection#bypassing-deployment-protection',
    'security/deployment-protection/methods-to-bypass-deployment-protection',
  ],
  [
    'security/deployment-protection#configuring-deployment-protection',
    'security/deployment-protection#understanding-deployment-protection-by-environment',
  ],
  // Vercel authentication
  [
    'security/deployment-protection#vercel-authentication',
    'security/deployment-protection/methods-to-protect-deployments/vercel-authentication',
  ],
  [
    'security/deployment-protection#who-can-access-a-deployment-protected-with-vercel-authentication',
    'security/deployment-protection/methods-to-protect-deployments/vercel-authentication#who-can-access-protected-deployments',
  ],
  [
    'security/deployment-protection#access-requests',
    'security/deployment-protection/methods-to-protect-deployments/vercel-authentication#access-requests',
  ],
  [
    'security/deployment-protection#vercel-authentication-considerations',
    'security/deployment-protection/methods-to-protect-deployments/vercel-authentication#vercel-authentication-security-considerations',
  ],
  [
    'security/deployment-protection#managing-vercel-authentication',
    'security/deployment-protection/methods-to-protect-deployments/vercel-authentication#managing-vercel-authentication',
  ],
  // Password protection
  [
    'security/deployment-protection#password-protection',
    'security/deployment-protection/methods-to-protect-deployments/password-protection',
  ],
  [
    'security/deployment-protection#password-protection-considerations',
    'security/deployment-protection/methods-to-protect-deployments/password-protection#password-protection-security-considerations',
  ],
  [
    'security/deployment-protection#security',
    'security/deployment-protection/methods-to-protect-deployments/password-protection#password-protection-security-considerations',
  ],
  [
    'security/deployment-protection#managing-password-protection',
    'security/deployment-protection/methods-to-protect-deployments/password-protection#managing-password-protection',
  ],
  // Trusted IPs
  [
    'security/deployment-protection#trusted-ips',
    'security/deployment-protection/methods-to-protect-deployments/trusted-ips',
  ],
  [
    'security/deployment-protection#trusted-ips-considerations',
    'security/deployment-protection/methods-to-protect-deployments/trusted-ips#trusted-ips-security-considerations',
  ],
  [
    'security/deployment-protection#prerequisites',
    'security/deployment-protection/methods-to-protect-deployments/trusted-ips#trusted-ips-security-considerations',
  ],
  [
    'security/deployment-protection#standalone-trusted-ips',
    'security/deployment-protection/methods-to-protect-deployments/trusted-ips#standalone-trusted-ips',
  ],
  [
    'security/deployment-protection#managing-trusted-ips',
    'security/deployment-protection/methods-to-protect-deployments/trusted-ips#managing-trusted-ips',
  ],
  // Standard protection
  [
    'security/deployment-protection#standard-protection',
    'security/deployment-protection#protecting-preview-and-generated-urls-with-standard-protection',
  ],
  // Sharable links
  [
    'security/deployment-protection#shareable-links',
    'security/deployment-protection/methods-to-bypass-deployment-protection/sharable-links',
  ],
  [
    'security/deployment-protection#who-can-create-shareable-links',
    'security/deployment-protection/methods-to-bypass-deployment-protection/sharable-links#who-can-create-shareable-links',
  ],
  [
    'security/deployment-protection#managing-sharable-links',
    'security/deployment-protection/methods-to-bypass-deployment-protection/sharable-links#managing-sharable-links',
  ],
  // Bypassing deployment protection
  [
    'security/deployment-protection#protection-bypass-for-automation',
    'security/deployment-protection/methods-to-bypass-deployment-protection/protection-bypass-automation',
  ],
  [
    'security/deployment-protection#who-can-manage-protection-bypass-for-automation',
    'security/deployment-protection/methods-to-bypass-deployment-protection/protection-bypass-automation#who-can-manage-protection-bypass-for-automation',
  ],
  [
    'security/deployment-protection#using-protection-bypass-for-automation',
    'security/deployment-protection/methods-to-bypass-deployment-protection/protection-bypass-automation#using-protection-bypass-for-automation',
  ],
  // Deployment protection exceptions
  [
    'security/deployment-protection#deployment-protection-exceptions',
    'security/deployment-protection/methods-to-bypass-deployment-protection/deployment-protection-exceptions',
  ],
  [
    'security/deployment-protection#adding-a-deployment-protection-exception',
    'security/deployment-protection/methods-to-bypass-deployment-protection/deployment-protection-exceptions#adding-a-deployment-protection-exception',
  ],
  [
    'security/deployment-protection#removing-a-deployment-protection-exception',
    'security/deployment-protection/methods-to-bypass-deployment-protection/deployment-protection-exceptions#removing-a-deployment-protection-exception',
  ],
  // Advanced deployment protection
  [
    'security/advanced-deployment-protection#enable-advanced-deployment-protection',
    'security/deployment-protection#enabling-advanced-deployment-protection',
  ],
  [
    'security/advanced-deployment-protection#disable-advanced-deployment-protection',
    'security/deployment-protection#disabling-advanced-deployment-protection',
  ],
  // Edge config
  [
    'concepts/edge-network/edge-config#get-started-and-learn-more',
    'edge-network/edge-config#more-benefits',
  ],
  [
    'file-system-api/v2#configuration/pre-rendering/preview-mode',
    'file-system-api/v2#configuration/pre-rendering/draft-mode',
  ],
  // frameworks redirects
  ['frameworks/nextjs#preview-mode', 'frameworks/nextjs#draft-mode'],
  [
    'frameworks/nextjs-on-vercel#using-next.js-with-vercel',
    'frameworks/nextjs-on-vercel#more-resources',
  ],
  [
    'frameworks/nextjs-on-vercel#more-resources',
    'frameworks/nextjs#more-resources',
  ],
  [
    'frameworks#frameworks-infrastructure-support-matrix',
    'frameworks/more-frameworks#frameworks-infrastructure-support-matrix',
  ],
  // Log Drains redirects
  ['rest-api#endpoints/log-drains', 'log-drains'],
  [
    'rest-api#introduction/api-basics/format-and-transport',
    'log-drains#format-and-transport',
  ],
  [
    'rest-api#introduction/api-basics/json-drains',
    'log-drains#format-and-transport/json-log-drains',
  ],
  [
    'rest-api#introduction/api-basics/ndjson-drains',
    'log-drains#format-and-transport/ndjson-log-drains',
  ],
  [
    'rest-api#introduction/api-basics/syslog-drain',
    'log-drains#format-and-transport/syslog-drain',
  ],
  [
    'rest-api#introduction/api-basics/securing-your-log-drains',
    'log-drains#secure-log-drains',
  ],
  // File System redirects
  ['file-system-api#assets', 'file-system-api#resources'],

  [
    'file-system-api#assets/serverless-functions',
    'file-system-api#resources/pages',
  ],
  // Project Configuration redirects
  ['configuration#project/headers', 'projects/project-configuration#headers'],
  [
    'project-configuration#project-configuration/build-command',
    'projects/project-configuration#buildcommand',
  ],
  [
    'project-configuration#project-configuration/clean-urls',
    'projects/project-configuration#cleanurls',
  ],
  [
    'project-configuration#project-configuration/dev-command',
    'projects/project-configuration#devcommand',
  ],
  [
    'project-configuration#project-configuration/framework',
    'projects/project-configuration#framework',
  ],
  [
    'project-configuration#project-configuration/functions',
    'projects/project-configuration#functions',
  ],
  [
    'project-configuration#project-configuration/functions/key-definition',
    'projects/project-configuration#key-definition',
  ],
  [
    'project-configuration#project-configuration/functions/value-definition',
    'projects/project-configuration#value-definition',
  ],
  [
    'project-configuration#project-configuration/functions/description',
    'projects/project-configuration#description',
  ],
  [
    'project-configuration#project-configuration/functions/description/functions-property-with-serverless-functions',
    'projects/project-configuration#functions-property-with-serverless-functions',
  ],
  [
    'project-configuration#project-configuration/functions/description/functions-property-with-isr',
    'projects/project-configuration#functions-property-with-isr',
  ],
  [
    'project-configuration#project-configuration/functions/using-unsupported-runtimes',
    'projects/project-configuration#using-unsupported-runtimes',
  ],
  [
    'project-configuration#project-configuration/headers',
    'projects/project-configuration#headers',
  ],
  [
    'project-configuration#project-configuration/ignore-command',
    'projects/project-configuration#ignorecommand',
  ],
  [
    'project-configuration#project-configuration/install-command',
    'projects/project-configuration#installcommand',
  ],
  [
    'project-configuration#project-configuration/output-directory',
    'projects/project-configuration#outputdirectory',
  ],
  [
    'project-configuration#project-configuration/public',
    'projects/project-configuration#public',
  ],
  [
    'project-configuration#project-configuration/redirects',
    'projects/project-configuration#redirects',
  ],
  [
    'project-configuration#project-configuration/regions',
    'projects/project-configuration#regions',
  ],
  [
    'project-configuration#project-configuration/rewrites',
    'projects/project-configuration#rewrites',
  ],
  [
    'project-configuration#project-configuration/trailing-slash',
    'projects/project-configuration#trailingslash',
  ],
  [
    'project-configuration#project-configuration/trailing-slash/false',
    'projects/project-configuration#false',
  ],
  [
    'project-configuration#project-configuration/trailing-slash/true',
    'projects/project-configuration#true',
  ],
  [
    'project-configuration#project-configuration/trailing-slash/undefined',
    'projects/project-configuration#undefined',
  ],
  ['project-configuration#legacy', 'projects/project-configuration#legacy'],
  ['project-configuration#legacy/name', 'projects/project-configuration#name'],
  [
    'project-configuration#legacy/version',
    'projects/project-configuration#version',
  ],
  [
    'project-configuration#legacy/alias',
    'projects/project-configuration#alias',
  ],
  [
    'project-configuration#legacy/scope',
    'projects/project-configuration#scope',
  ],
  ['project-configuration#legacy/env', 'projects/project-configuration#env'],
  [
    'project-configuration#legacy/build-env',
    'projects/project-configuration#build.env',
  ],
  [
    'project-configuration#legacy/builds',
    'projects/project-configuration#builds',
  ],
  [
    'project-configuration#legacy/routes',
    'projects/project-configuration#routes',
  ],
  [
    'project-configuration#legacy/routes/upgrading',
    'projects/project-configuration#upgrading-legacy-routes',
  ],
  // Project Configuration / Git Configuration redirects
  [
    'project-configuration#git-configuration',
    'projects/project-configuration/git-configuration',
  ],
  [
    'project-configuration#git-configuration/git-deployment-enabled',
    'projects/project-configuration/git-configuration#git.deploymentenabled',
  ],
  [
    'project-configuration#git-configuration/github-auto-alias',
    'projects/project-configuration/git-configuration#github.autoalias',
  ],
  [
    'project-configuration#git-configuration/github-silent',
    'projects/project-configuration/git-configuration#github.silent',
  ],
  [
    'project-configuration#git-configuration/github-auto-job-cancelation',
    'projects/project-configuration/git-configuration#github.autojobcancelation',
  ],
  [
    'project-configuration#git-configuration/github-enabled',
    'project-configuration#git-configuration/github-enabled',
  ],
  // Project Configuration / Global Configuration redirects
  [
    'project-configuration#global-configuration',
    'projects/project-configuration/global-configuration',
  ],
  [
    'project-configuration#global-configuration/config-json',
    'projects/project-configuration/global-configuration#config.json',
  ],
  [
    'project-configuration#global-configuration/config-json/current-team',
    'projects/project-configuration/global-configuration#currentteam',
  ],

  [
    'project-configuration#global-configuration/config-json/collect-metrics',
    'projects/project-configuration/global-configuration#collectmetrics',
  ],
  [
    'project-configuration#global-configuration/auth-json',
    'project-configuration/global-configuration#auth.json',
  ],
  //webhooks redirects
  [
    'webhooks#introduction/supported-event-types',
    'integrations/working-with-webhooks/webhooks-api#supported-event-types',
  ],
  [
    'webhooks#introduction/securing-webhooks',
    'integrations/working-with-webhooks/webhooks-api#securing-webhooks',
  ],
  [
    'webhooks#introduction/http-response',
    'integrations/working-with-webhooks/webhooks-api#http-response',
  ],
  [
    'webhooks#introduction/delivery-attempts-and-retries',
    'integrations/working-with-webhooks/webhooks-api#delivery-attempts-and-retries',
  ],
  [
    'observability/webhooks-overview#configurable-webhooks',
    'observability/webhooks-overview#account-webhooks',
  ],
  [
    'observability/webhooks-overview#creating-a-new-integration',
    'observability/webhooks-overview#integration-webhooks',
  ],

  // Checks Docs redirects
  [
    'rest-api#introduction/api-basics/status-and-conclusion',
    'checks#introduction/status-and-conclusion',
  ],
  [
    'rest-api#introduction/api-basics/output',
    'checks#introduction/status-and-conclusion/output',
  ],
  [
    'docs/checks#introduction/status-and-conclusion',
    'integrations/working-with-checks/checks-api#status-and-conclusion',
  ],
  [
    'checks#introduction/status-and-conclusion/output',
    'integrations/working-with-checks/checks-api#output',
  ],
  //Custom domains redirects - redirect from /domains instead of /custom-domains as custom-domains is already redirected to domains from static redirects
  [
    'concepts/projects/domains#adding-a-domain',
    'projects/domains/add-a-domain',
  ],
  [
    'concepts/projects/domains#step-2:-navigating-to-domain-settings',
    'projects/domains/add-a-domain#navigating-to-domain-settings',
  ],
  [
    'concepts/projects/domains#step-3:-entering-your-chosen-domain',
    'projects/domains/add-a-domain#entering-your-chosen-domain',
  ],
  [
    'concepts/projects/domainss#wildcard-domains',
    'projects/domains/add-a-domain#wildcard-domains',
  ],
  [
    'concepts/projects/domains#verification-challenge',
    'projects/domains/add-a-domain#verification-challenge',
  ],
  [
    'concepts/projects/domains#step-4:-configuring-the-domain',
    'projects/domains/add-a-domain#configuring-the-domain',
  ],
  [
    'concepts/projects/domains#apex-domains',
    'projects/domains/add-a-domain#apex-domains',
  ],
  [
    'concepts/projects/domains#subdomains',
    'projects/domains/add-a-domain#subdomains',
  ],
  [
    'concepts/projects/domains#vercel-nameservers',
    'projects/domains/add-a-domain#vercel-nameservers',
  ],
  [
    'concepts/projects/domains#step-5:-domain-configured',
    'projects/domains/add-a-domain#domain-configured',
  ],
  [
    'concepts/projects/domains#removing-a-domain',
    'projects/domains/remove-a-domain',
  ],
  [
    'concepts/projects/domains#renewing-domains',
    'projects/domains/renew-a-domain',
  ],
  [
    'concepts/projects/domains#renewing-domains?utm_medium=email&utm_source=notifications',
    'projects/domains/renew-a-domain?utm_medium=email&utm_source=notifications',
  ],
  [
    'concepts/projects/domains#auto-renewal-off',
    'projects/domains/renew-a-domain#auto-renewal-off',
  ],
  [
    'concepts/projects/domains#auto-renewal-on',
    'projects/domains/renew-a-domain#auto-renewal-on',
  ],
  [
    'concepts/projects/domains#dns-records',
    'projects/domains/managing-dns-record',
  ],
  [
    'concepts/projects/domains#step-1:-selecting-your-domain',
    'projects/domains/managing-dns-records#selecting-your-domain',
  ],
  [
    'concepts/projects/domains#step-2:-add-dns-record',
    'projects/domains/managing-dns-records#add-dns-record',
  ],
  [
    'concepts/projects/domains#dns-email-presets',
    'projects/domains/managing-dns-records#dns-email-presets',
  ],
  [
    'concepts/projects/domains#nameservers',
    'projects/domains/managing-nameservers',
  ],
  [
    'concepts/projects/domains#add-custom-nameservers',
    'projects/domains/managing-nameservers#add-custom-nameservers',
  ],
  [
    'concepts/projects/domains#restore-original-nameservers',
    'projects/domains/managing-nameservers#restore-original-nameservers',
  ],
  [
    'concepts/projects/domains#custom-ssl-certificates',
    'projects/domains/custom-SSL-certificate',
  ],
  [
    'concepts/projects/domains#deploying-with-your-domain',
    'projects/domains/deploying-and-redirecting#deploying-your-domain',
  ],
  [
    'concepts/projects/domains#redirecting-domains',
    'projects/domains/deploying-and-redirecting#redirecting-domains',
  ],
  [
    'concepts/projects/domains#redirect-status-codes',
    'projects/domains/deploying-and-redirecting#redirect-status-codes',
  ],
  [
    'concepts/projects/domains#domain-ownership',
    'projects/domains/troubleshooting#domain-ownership-errors',
  ],
  [
    'concepts/projects/domains#assigning-a-domain-to-a-git-branch',
    'projects/domains/assign-domain-to-a-git-branch',
  ],
  [
    'concepts/projects/domains#transferring-domains-out',
    'projects/domains/transfer-your-domain#transferring-domains-out-of-vercel',
  ],
  [
    'concepts/projects/domains#moving-domains',
    'projects/domains/transfer-your-domain#transfer-a-domain-to-another-vercel-user-or-team',
  ],
  [
    'concepts/projects/domains#searching-domains',
    'projects/domains/view-and-search-domains#searching-domains',
  ],
  [
    'concepts/projects/domains#view-and-search-your-domains',
    'projects/domains/view-and-search-domains',
  ],
  //Accounts management
  [
    'accounts/team-members-and-roles#managing-team-members',
    'accounts/team-members-and-roles/managing-team-members',
  ],
  [
    'accounts/team-members-and-roles#invite-link',
    'accounts/team-members-and-roles/managing-team-members#invite-link',
  ],
  [
    'accounts/team-members-and-roles#request-access',
    'accounts/team-members-and-roles/managing-team-members#request-access',
  ],
  [
    'accounts/team-members-and-roles#managing-team-roles',
    '/accounts/team-members-and-roles/managing-team-members#adding-team-members-and-assigning-roles',
  ],
  [
    'accounts/team-members-and-roles#pro-team-roles',
    'accounts/team-members-and-roles/access-roles',
  ],
  [
    'accounts/team-members-and-roles#owner-role',
    'accounts/team-members-and-roles/access-roles#owner-role',
  ],
  [
    'accounts/team-members-and-roles#member-role',
    'accounts/team-members-and-roles/access-roles#member-role',
  ],
  [
    'accounts/team-members-and-roles#enterprise-team-roles',
    'accounts/team-members-and-roles/access-roles',
  ],
  [
    'accounts/team-members-and-roles#developer-role',
    'accounts/team-members-and-roles/access-roles#developer-role',
  ],
  [
    'accounts/team-members-and-roles#billing-role',
    'accounts/team-members-and-roles/access-roles#billing-role',
  ],
  [
    'accounts/team-members-and-roles#project-administrators',
    'accounts/team-members-and-roles/access-roles#project-administrators',
  ],
  [
    'accounts/team-members-and-roles#viewer-role',
    'accounts/team-members-and-roles/access-roles#viewer-role',
  ],
  [
    'accounts/team-members-and-roles#role-permissions-comparison',
    'accounts/team-members-and-roles/access-roles#role-permissions-comparison',
  ],
  [
    'accounts#creating-a-personal-account',
    'accounts/create-an-account#creating-a-personal-account',
  ],
  [
    'accounts#sign-up-with-email',
    'accounts/create-an-account#sign-up-with-email',
  ],
  [
    'accounts#sign-up-with-git-provider',
    'accounts/create-an-account#sign-up-with-git-provider',
  ],
  [
    'accounts#login-methods-and-connections',
    'accounts/create-an-account#login-methods-and-connections',
  ],
  [
    'accounts#choosing-a-connection-when-creating-a-project',
    'accounts/create-an-account#choosing-a-connection-when-creating-a-project',
  ],
  [
    'accounts#existing-login-connection',
    'accounts/create-an-account#existing-login-connection',
  ],
  [
    'accounts/create-or-join-a-team#creating-a-team',
    'accounts/create-a-team#creating-a-team',
  ],
  //Older redirects for accounts
  [
    'concepts/personal-accounts/login-connections#existing-login-connection',
    'accounts/create-an-account#existing-login-connection',
  ],
  ['concepts/teams/overview#creating-a-team', 'accounts/create-a-team'],
  [
    'concepts/teams/overview#switching-between-users-and-teams',
    'accounts/create-a-team#creating-a-team',
  ],
  [
    'concepts/teams/overview#leaving-the-team',
    'accounts/create-a-team#leaving-the-team',
  ],
  [
    'concepts/members#inviting-new-team-members',
    'accounts/team-members-and-roles/managing-team-members',
  ],
  [
    'concepts/teams/members#team-membership',
    'accounts/create-a-team#team-membership',
  ],
  [
    'concepts/teams/members#suggested-teams',
    'accounts/create-a-team#suggested-teams',
  ],
  [
    'concepts/teams/members#requesting-access-to-suggested-teams',
    'accounts/create-a-team#suggested-teams',
  ],
  [
    'concepts/teams/members#dismissing-suggested-teams',
    'accounts/create-a-team#suggested-teams',
  ],
  [
    'concepts/personal-accounts/team-membership#suggested-teams',
    'accounts/create-a-team#suggested-teams',
  ],
  [
    'concepts/personal-accounts/team-membership#requesting-access-to-suggested-teams',
    'accounts/create-a-team#suggested-teams',
  ],
  [
    'concepts/personal-accounts/team-membership#dismissing-suggested-teams',
    'accounts/create-a-team#suggested-teams',
  ],
  [
    'concepts/personal-accounts/team-membership#suggested-teams',
    'accounts/create-a-team#suggested-teams',
  ],
  [
    'concepts/teams/team-membership#suggested-teams',
    'accounts/create-a-team#suggested-teams',
  ],
  [
    'concepts/teams/team-membership#requesting-access-to-suggested-teams',
    'accounts/create-a-team#suggested-teams',
  ],
  [
    'concepts/teams/team-membership#dismissing-suggested-teams',
    'accounts/create-a-team#suggested-teams',
  ],
  [
    'teams-and-accounts/team-members-and-roles#team-email-domain',
    'accounts/create-a-team#team-email-domain',
  ],
  ['concepts/teams/overview', 'accounts/create-a-team'],
  ['concepts/deployments/build-step#build-image', 'deployments/build-image'],
  ['functions/edge-functions/streaming', 'functions/streaming'],
  [
    'concepts/functions/edge-functions#known-limitations',
    'functions/edge-functions#limitations',
  ],
  [
    'concepts/functions/edge-functions/getting-started-with-edge-functions#prerequisites',
    'functions/edge-functions/quickstart#prerequisites',
  ],
  [
    'concepts/functions/edge-functions/getting-started-with-edge-functions#create-your-first-edge-function',
    'functions/edge-functions/quickstart#create-your-first-edge-function',
  ],
  // Edge Middleware
  [
    'concepts/functions/edge-functions/middleware#middleware-usage',
    'functions/edge-middleware#create-edge-middleware',
  ],
  [
    'concepts/functions/edge-middleware#creating-edge-middleware',
    'functions/edge-middleware#create-edge-middleware',
  ],
  [
    'concepts/functions/edge-middleware#what-is-edge-middleware',
    'functions/edge-middleware',
  ],
  [
    'concepts/functions/edge-middleware/quickstart#create-your-first-edge-middleware',
    'functions/edge-middleware#create-edge-middleware',
  ],
  [
    'concepts/functions/edge-functions/middleware#runtime',
    'functions/edge-middleware',
  ],
  [
    'concepts/functions/edge-functions/middleware-api#nextfetchevent',
    'functions/edge-middleware/middleware-api',
  ],
  [
    'concepts/functions/edge-functions/middleware-api#nextrequest',
    'functions/edge-middleware/middleware-api',
  ],
  [
    'concepts/functions/edge-functions/middleware-api#nextresponse',
    'functions/edge-middleware/middleware-api',
  ],
  [
    'concepts/functions/edge-functions/middleware-api#why-does-redirect()-use-307-and-308',
    'functions/edge-middleware/middleware-api',
  ],
  [
    'concepts/functions/edge-functions/middleware-api#runtime-apis',
    'functions/edge-middleware/middleware-api',
  ],
  [
    'concepts/functions/edge-middleware#known-limitations',
    'functions/edge-middleware/limitations',
  ],
  [
    'concepts/functions/edge-functions/middleware-api#unsupported-apis-and-runtime-restrictions',
    'functions/edge-middleware/middleware-api#unsupported-apis-and-runtime-restrictions',
  ],
  [
    'concepts/functions/edge-middleware#match-paths-based-on-custom-matcher-config',
    'functions/edge-middleware/middleware-api#match-paths-based-on-custom-matcher-config',
  ],
  [
    'concepts/functions/edge-middleware#match-a-single-path',
    'functions/edge-middleware/middleware-api#match-a-single-path',
  ],
  [
    'concepts/functions/edge-middleware#match-multiple-paths',
    'functions/edge-middleware/middleware-api#match-multiple-paths',
  ],
  [
    'concepts/functions/edge-middleware#match-using-regex',
    'functions/edge-middleware/middleware-api#match-using-regex',
  ],
  [
    'concepts/functions/edge-middleware#match-paths-based-on-conditional-statements',
    'functions/edge-middleware/middleware-api#match-paths-based-on-conditional-statements',
  ],
  [
    'concepts/functions/edge-middleware/middleware-api#edge-middleware-with-next.js',
    'functions/edge-middleware#create-edge-middleware',
  ],
  [
    'concepts/functions/edge-middleware/middleware-api#the-@vercel/edge-package',
    'functions/edge-functions/vercel-edge-package',
  ],
  [
    'concepts/functions/edge-middleware/middleware-api#rewrite',
    'functions/edge-functions/vercel-edge-package#rewrite',
  ],
  [
    'concepts/functions/edge-middleware/middleware-api#geolocation',
    'functions/edge-functions/vercel-edge-package#geolocation',
  ],
  [
    'concepts/functions/edge-middleware/middleware-api#ipaddress',
    'functions/edge-functions/vercel-edge-package#ipaddress',
  ],
  [
    'concepts/functions/edge-middleware/middleware-api#next',
    'functions/edge-functions/vercel-edge-package#next',
  ],
  [
    'concepts/functions/edge-functions/edge-functions-limitations#code-size-limit',
    'functions/edge-functions/limitations#code-size-limit',
  ],
  [
    'concepts/functions/edge-functions/edge-functions-limitations#maximum-execution-duration',
    'functions/edge-functions/limitations#maximum-initial-response-time',
  ],
  [
    'concepts/functions/edge-functions/limitations#maximum-execution-duration',
    'functions/edge-functions/limitations#maximum-initial-response-time',
  ],
  [
    'concepts/functions/edge-functions/edge-functions-limitations#api-and-runtime-limitations',
    'functions/edge-functions/limitations#node.js-apis-are-not-available',
  ],
  [
    'concepts/functions/edge-functions/edge-functions-limitations#limits-on-environment-variables',
    'functions/edge-functions/limitations',
  ],
  [
    'concepts/functions/serverless-functions/conceptual-model#versus-processes-and-containers',
    'functions/concepts/conceptual-model#versus-processes-and-containers',
  ],
  [
    'concepts/functions/serverless-functions/conceptual-model#per-entrypoint-granularity',
    'functions/concepts/conceptual-model#per-entrypoint-granularity',
  ],
  [
    'concepts/functions/serverless-functions/conceptual-model#lifecycle-and-scalability',
    'functions/concepts/conceptual-model#lifecycle-and-scalability',
  ],
  [
    'concepts/functions/serverless-functions/conceptual-model#cold-and-hot-boots',
    'functions/concepts/conceptual-model#cold-and-hot-boots',
  ],
  // Caching
  ['functions/serverless-functions/edge-caching', 'edge-network/caching'],
  //tutorial redirects
  [
    'concepts/get-started#deploy-an-existing-project',
    'get-started/deploy#import-an-existing-project',
  ],
  ['concepts/get-started#up-next', 'concepts/get-started/deploy#up-next'],
  ['concepts/customize#routing-rules', 'get-started/customize#routing-rules'],
  ['concepts/customize#api-routes', 'get-started/customize#api-routes'],
  [
    'concepts/customize#environment-variables',
    'get-started/customize#environment-variables',
  ],
  ['concepts/customize#up-next', 'concepts/get-started/customize#up-next'],
  [
    'concepts/assign-domain#default-domain',
    'get-started/assign-domain#default-domain',
  ],
  [
    'concepts/assign-domain#custom-domain',
    'get-started/assign-domain#custom-domain',
  ],
  ['concepts/assign-domain#up-next', 'get-started/assign-domain#up-next'],
  [
    'concepts/make-changes#applying-updates-via-git',
    'get-started/make-changes#applying-updates-via-git',
  ],
  [
    'concepts/make-changes#environments',
    'get-started/make-changes#environments',
  ],
  [
    'concepts/make-changes#applying-updates-via-cli',
    'get-started/make-changes#applying-updates-via-cli',
  ],
  ['concepts/make-changes#up-next', 'get-started/make-changes#up-next'],
  [
    'concepts/collaborate#creating-a-team',
    'get-started/collaborate#creating-a-team',
  ],
  [
    'concepts/collaborate#letting-others-join-your-team',
    'get-started/collaborate#letting-others-join-your-team',
  ],
  [
    'concepts/collaborate#joining-through-your-git-repository',
    'get-started/collaborate#joining-through-your-git-repository',
  ],
  [
    'concepts/collaborate#inviting-others-into-your-team',
    'get-started/collaborate#inviting-others-into-your-team',
  ],
  [
    'concepts/get-started/compute#client-side-rendering-(csr)',
    'get-started/compute#client-side-rendering-csr',
  ],
  [
    'concepts/get-started/compute#server-side-rendering-(ssr)',
    'get-started/compute#server-side-rendering-ssr',
  ],
  [
    'concepts/get-started/compute#static-site-generation-(ssg)',
    'get-started/compute#static-site-generation-ssg',
  ],

  // SAML redirects
  [
    'concepts/teams/saml#configuring-saml-sso',
    'teams/security-and-compliance#saml-single-sign-ons',
  ],
  [
    'concepts/teams/saml#authenticating-with-saml-sso',
    'teams-and-accounts/security-and-compliance#authenticating-with-saml-sso',
  ],
  [
    'concepts/teams/saml#enforcing-saml',
    'teams-and-accounts/security-and-compliance#enforcing-saml',
  ],
  [
    'concepts/teams/saml#directory-sync',
    'teams-and-accounts/security-and-compliance#directory-sync',
  ],
  [
    'concepts/teams/saml#configuring-directory-sync',
    'teams-and-accounts/security-and-compliance#configuring-directory-sync',
  ],
  // Runtime hash redirects
  [
    'runtimes#advanced-usage/developing-your-own-runtime',
    'functions/serverless-functions/runtimes#developing-your-own-runtime',
  ],
  [
    'runtimes#advanced-usage/technical-details',
    'functions/serverless-functions/runtimes#caching-data',
  ],
  [
    'runtimes#advanced-usage/technical-details/caching-data',
    'functions/serverless-functions/runtimes#caching-data',
  ],
  [
    'runtimes#advanced-usage/technical-details/limits',
    'functions/serverless-functions/runtimes#limits',
  ],
  [
    'runtimes#advanced-usage/technical-details/including-additional-files',
    'functions/serverless-functions/runtimes#including-additional-files',
  ],
  // Node.js runtime
  [
    'runtimes#official-runtimes/node-js',
    'functions/serverless-functions/runtimes/node-js',
  ],
  [
    'runtimes#official-runtimes/node-js/node-js-version',
    'functions/serverless-functions/runtimes/node-js#node.js-version',
  ],
  [
    'runtimes#official-runtimes/node-js/node-js-dependencies',
    'functions/serverless-functions/runtimes/node-js#node.js-dependencies',
  ],
  [
    'runtimes#official-runtimes/node-js/using-type-script-with-the-node-js-runtime',
    'functions/serverless-functions/runtimes/node-js#using-typescript-with-the-node.js-runtime',
  ],
  [
    'runtimes#official-runtimes/node-js/node-js-request-and-response-objects',
    'functions/serverless-functions/runtimes/node-js#node.js-request-and-response-objects',
  ],
  [
    'runtimes#official-runtimes/node-js/node-js-request-and-response-objects/node-js-helpers',
    'functions/serverless-functions/runtimes/node-js#node.js-helpers',
  ],
  [
    'runtimes#official-runtimes/node-js/node-js-request-and-response-objects/request-body',
    'functions/serverless-functions/runtimes/node-js#request-body',
  ],
  [
    'runtimes#advanced-usage/advanced-node-js-usage',
    'functions/serverless-functions/runtimes/node-js#advanced-node.js-usage',
  ],
  [
    'runtimes#advanced-usage/advanced-node-js-usage/disabling-helpers-for-node-js',
    'functions/serverless-functions/runtimes/node-js#disabling-helpers-for-node.js',
  ],
  [
    'runtimes#advanced-usage/advanced-node-js-usage/private-npm-modules-for-node-js',
    'functions/serverless-functions/runtimes/node-js#private-npm-modules-for-node.js',
  ],
  [
    'runtimes#advanced-usage/advanced-node-js-usage/custom-build-step-for-node-js',
    'functions/serverless-functions/runtimes/node-js#custom-build-step-for-node.js',
  ],
  [
    'runtimes#advanced-usage/advanced-node-js-usage/legacy-serverful-behavior',
    'functions/serverless-functions/runtimes/node-js#legacy-serverful-behavior',
  ],
  [
    'runtimes#advanced-usage/advanced-node-js-usage/aws-lambda-api',
    'functions/serverless-functions/runtimes/node-js#aws-lambda-api',
  ],
  // Go runtime
  [
    'runtimes#official-runtimes/go',
    'functions/serverless-functions/runtimes/go',
  ],
  [
    'runtimes#official-runtimes/go/go-version',
    'functions/serverless-functions/runtimes/go#go-version',
  ],
  [
    'runtimes#official-runtimes/go/go-dependencies',
    'functions/serverless-functions/runtimes/go#go-dependencies',
  ],
  [
    'runtimes#official-runtimes/go/go-build-configuration',
    'functions/serverless-functions/runtimes/go#go-build-configuration',
  ],
  [
    'runtimes#advanced-usage/advanced-go-usage',
    'functions/serverless-functions/runtimes/go#advanced-go-usage',
  ],
  [
    'runtimes#advanced-usage/advanced-go-usage/private-packages-for-go',
    'functions/serverless-functions/runtimes/go#private-packages-for-go',
  ],
  // Python runtime
  [
    'runtimes#official-runtimes/python',
    'functions/serverless-functions/runtimes/python',
  ],
  [
    'runtimes#official-runtimes/python/python-version',
    'functions/serverless-functions/runtimes/python#python-version',
  ],
  [
    'runtimes#official-runtimes/python/python-dependencies',
    'functions/serverless-functions/runtimes/python#python-dependencies',
  ],
  [
    'runtimes#advanced-usage/advanced-python-usage',
    'functions/serverless-functions/runtimes/python#advanced-python-usage',
  ],
  [
    'runtimes#advanced-usage/advanced-python-usage/reading-relative-files-in-python',
    'functions/serverless-functions/runtimes/python#reading-relative-files-in-python',
  ],
  [
    'runtimes#advanced-usage/advanced-python-usage/web-server-gateway-interface',
    'functions/serverless-functions/runtimes/python#web-server-gateway-interface',
  ],
  [
    'runtimes#advanced-usage/advanced-python-usage/asynchronous-server-gateway-interface',
    'functions/serverless-functions/runtimes/python#asynchronous-server-gateway-interface',
  ],
  // Ruby runtime
  [
    'runtimes#official-runtimes/ruby',
    'functions/serverless-functions/runtimes/ruby',
  ],
  [
    'runtimes#official-runtimes/ruby/ruby-version',
    'functions/serverless-functions/runtimes/ruby#ruby-version',
  ],
  [
    'runtimes#official-runtimes/ruby/ruby-dependencies',
    'functions/serverless-functions/runtimes/ruby#ruby-dependencies',
  ],
  [
    'security#penetration-testing-&-audit-scans',
    'security#penetration-testing-and-audit-scans',
  ],
  [
    'concepts/functions/edge-middleware/quickstart#create-edge-middleware-using-next.js',
    'functions/edge-middleware/quickstart#create-edge-middleware-using-next.js-middleware',
  ],
  [
    'integrations/otel-overview/quickstart#prerequisites',
    'observability/otel-overview/quickstart#prerequisites',
  ],
  [
    'integrations/otel-overview/quickstart#get-started',
    'observability/otel-overview/quickstart#get-started',
  ],
  [
    'integrations/otel-overview/quickstart#find-the-sdk',
    'observability/otel-overview/quickstart#find-the-sdk',
  ],
  [
    'integrations/otel-overview/quickstart#instrument-your-project-with-otel',
    'observability/otel-overview/quickstart#instrument-your-project-with-otel',
  ],
  [
    'integrations/otel-overview/quickstart#deploy-your-project-to-vercel',
    'observability/otel-overview/quickstart#deploy-your-project-to-vercel',
  ],
  [
    'integrations/otel-overview/quickstart#-install-an-otel-integration-to-visualize-traces',
    'observability/otel-overview/quickstart#-install-an-otel-integration-to-visualize-traces',
  ],
  ['collaborate#up-next', 'getting-started-with-vercel/collaborate'],
  ['monitor#logs', 'observability/runtime-logs'],
  ['monitor#analytics', 'analytics'],
  ['monitor#checks', 'observability'],
  [
    'analytics/limits-and-pricing#pro-plan-add-ons',
    'analytics/limits-and-pricing#pro-with-web-analytics-plus',
  ],
  [
    'teams-and-accounts#creating-a-team',
    'teams-and-accounts/create-or-join-a-team#creating-a-team',
  ],
  [
    'teams-and-accounts#letting-others-join-your-team',
    'teams-and-accounts/team-members-and-roles#managing-team-members',
  ],
  [
    'teams-and-accounts#joining-through-email-domain',
    'teams-and-accounts/team-members-and-roles#joining-through-email-domain',
  ],
  [
    'teams-and-accounts#joining-through-your-git-repository',
    'teams-and-accounts/team-members-and-roles#joining-through-your-git-repository',
  ],
  [
    'teams-and-accounts#joining-through-invite',
    'teams-and-accounts/team-members-and-roles#invite-link',
  ],
  ['concepts/observability#logs', 'observability/runtime-logs'],
  ['concepts/observability#analytics', 'speed-insights'],
  ['concepts/observability#checks', 'integrations/checks-overview'],
  ['concepts/observability#monitoring', 'observability/monitoring'],
  [
    'concepts/edge-network/frequently-asked-questions#why-do-i-see-a-404-error-when-accessing-my-deployment',
    'errors/platform-error-codes#404:-not_found',
  ],
  [
    'edge-network/frequently-asked-questions#what-locations-does-the-vercel-edge-network-in',
    'edge-network/frequently-asked-questions#what-locations-does-the-vercel-edge-network-cover',
  ],
  [
    'accounts/plans/pro#how-long-after-my-trial-will-i-be-billed',
    'accounts/plans/free-trials#how-long-after-my-trial-will-i-be-billed',
  ],
  [
    'accounts/plans/pro#what-happens-if-i-resume-using-vercel-several-months-after-my-trial-ends',
    'accounts/plans/free-trials#what-happens-if-i-resume-using-vercel-several-months-after-my-trial-ends',
  ],
  [
    'accounts/plans/pro#how-do-team-member-add/removal-(mid-term)-costs-work',
    'accounts/plans/pro#how-do-team-member-add-removal-mid-term-costs-work',
  ],
  [
    'concepts/limits/overview#functions-comparison',
    'functions/serverless-functions/runtimes',
  ],
  [
    'concepts/image-optimization#source-images',
    'image-optimization/limits-and-pricing#source-images',
  ],
  [
    'concepts/image-optimization#limits-and-pricing',
    'image-optimization#limits,-pricing,-and-performance-measurement',
  ],
  [
    'concepts/image-optimization#measuring-performance',
    'image-optimization#limits,-pricing,-and-performance-measurement',
  ],
  [
    'concepts/image-optimization/examples#using-next/image-on-vercel',
    'image-optimization/examples',
  ],
  [
    'concepts/image-optimization/examples#using-nuxt/image-on-vercel',
    'image-optimization/examples',
  ],
  [
    'concepts/image-optimization/managing-image-optimization-costs#should-i-be-optimizing-my-images',
    'image-optimization#should-i-optimize-my-images-on-vercel',
  ],
  [
    'concepts/image-optimization/managing-image-optimization-costs#how-to-minimize-image-optimization-costs',
    'image-optimization/managing-image-optimization-costs#minimizing-image-optimization-costs',
  ],
  [
    'concepts/image-optimization/managing-image-optimization-costs#is-vercel-best-suited-to-host-my-images',
    'image-optimization#should-i-optimize-my-images-on-vercel',
  ],
  [
    'concepts/observability/runtime-logs#request',
    'observability/runtime-logs#request-method',
  ],
  [
    'observability/runtime-logs#request',
    'observability/runtime-logs#request-method',
  ],
  [
    'limits/overview#typical-monthly-usage-guidelines',
    'limits/overview#included-usage',
  ],
  [
    'limits/overview#serverless-function-size',
    'functions/serverless-functions/runtimes#bundle-size-limits',
  ],
  [
    'limits/overview#serverless-function-architecture',
    'functions/serverless-functions/runtimes#architecture',
  ],
  [
    'limits/overview#serverless-function-memory',
    'functions/serverless-functions/runtimes#memory-size-limits',
  ],
  [
    'limits/overview#serverless-function-execution-timeout',
    'functions/serverless-functions/runtimes#max-duration',
  ],
  [
    'limits/overview#serverless-function-concurrency',
    'functions/serverless-functions/runtimes#automatic-concurrency-scaling',
  ],
  [
    'functions/serverless-functions/runtimes#concurrency-model',
    'functions/serverless-functions/runtimes#automatic-concurrency-scaling',
  ],
  [
    'limits/overview#serverless-function-payload-size-limit',
    'functions/serverless-functions/runtimes#request-body-size',
  ],
  [
    'limits/overview#serverless-function-regions',
    'functions/serverless-functions/runtimes#location',
  ],
  [
    'limits/overview#serverless-function-archiving',
    'functions/serverless-functions/runtimes#archiving',
  ],
  [
    'limits/overview#edge-middleware-and-edge-functions-size',
    'functions/serverless-functions/runtimes#bundle-size-limits',
  ],
  [
    'limits/overview#edge-middleware-cpu-limit',
    'observability/runtime-logs#request-method',
  ],
  [
    'limits/overview#edge-middleware-and-edge-functions-initial-response-timeout',
    'functions/serverless-functions/runtimes#max-execution-time',
  ],
  [
    'limits/overview#edge-middleware-cpu-limit',
    'functions/serverless-functions/runtimes#edge-middleware-cpu-limit',
  ],
  [
    'infrastructure/compute#serverless-functions-lifecycle',
    'functions/serverless-functions/runtimes#serverless-functions-lifecycle',
  ],
  [
    'limits/overview#serverless-function-architecture',
    'functions/serverless-functions/runtimes#functions-created-per-deployment',
  ],
  [
    'functions/serverless-functions/runtimes#max-execution-time',
    'functions/serverless-functions/runtimes#max-duration',
  ],
  [
    'functions/edge-functions/usage-and-pricing#pricing',
    'functions/edge-functions/usage-and-pricing',
  ],
  [
    'functions/usage-and-pricing#what-is-an-execution-unit?',
    'pricing/edge-functions#managing-execution-units',
  ],
  [
    'functions/usage-and-pricing#usage',
    'functions/edge-functions/usage-and-pricing',
  ],
  [
    'functions/usage-and-pricing#cpu-time-limits',
    'pricing/edge-functions#managing-cpu-time',
  ],
  [
    'functions/usage-and-pricing#cpu-utilization',
    'pricing/edge-functions#managing-cpu-time',
  ],
  [
    'functions/usage-and-pricing#billing-information',
    'functions/usage-and-pricing',
  ],
  [
    'functions/serverless-functions/runtimes#max-execution-duration',
    'functions/serverless-functions/runtimes#max-duration',
  ],
  [
    'functions/serverless-functions#execution-timeout',
    'functions/serverless-functions/runtimes#max-duration',
  ],
  [
    'storage/edge-config/edge-config-dashboard#create-an-edge-config',
    'storage/edge-config/edge-config-dashboard#creating-an-edge-config',
  ],
  [
    'storage/edge-config/edge-config-dashboard#manage-edge-configs',
    'storage/edge-config/edge-config-dashboard#managing-edge-configs',
  ],
  [
    'storage/edge-config/edge-config-dashboard#manage-items-in-the-store',
    'storage/edge-config/edge-config-dashboard#managing-items-in-the-store',
  ],
  [
    'storage/edge-config/edge-config-dashboard#manage-connected-projects',
    'storage/edge-config/edge-config-dashboard#managing-connected-projects',
  ],
  [
    'storage/edge-config/edge-config-dashboard#manage-read-access-tokens',
    'storage/edge-config/edge-config-dashboard#managing-read-access-tokens',
  ],
  [
    'functions/serverless-functions/runtimes#maxduration',
    'functions/configuring-functions/duration',
  ],
  [
    'projects/project-configuration#key-definition',
    'projects/project-configuration#glob-pattern',
  ],
  [
    'projects/project-configuration#value-definition',
    'projects/project-configuration#object-definition',
  ],
  [
    'projects/project-configuration#description',
    'projects/project-configuration#functions',
  ],
  [
    'functions/edge-functions#regional-edge-function-invocation',
    'functions/configuring-functions/region',
  ],
  [
    'security/advanced-deployment-protection#password-protection',
    'security/deployment-protection/methods-to-protect-deployments/password-protection',
  ],
  [
    'workflow-collaboration/conformance/index#conformance-allowlist',
    'workflow-collaboration/conformance/index#conformance-allowlists',
  ],
  [
    'workflow-collaboration/code-owners/code-approvers#permissions',
    'workflow-collaboration/code-owners/code-approvers',
  ],
  [
    'workflow-collaboration/conformance/allowlist#allowlist-location',
    'workflow-collaboration/conformance/allowlist#allowlists-location',
  ],
  [
    'deployments/managing-deployments#filter-deployments-by-branch',
    'deployments/managing-deployments#filter-deployment',
  ],
  [
    'deployments/managing-deployments#filter-deployments-by-environment',
    'deployments/managing-deployments#filter-deployment',
  ],
  [
    'deployments/managing-deployments#filter-deployments-by-status',
    'deployments/managing-deployments#filter-deployment',
  ],
  [
    'deployments/instant-rollback#comparing-instant-rollback-and-promote-to-production',
    'deployments/instant-rollback#comparing-instant-rollback-and-manual-promote-options',
  ],
  [
    'deployments/managing-deployments#comparing-manual-deployment-options',
    'deployments/managing-deployments#manually-promoting-to-production',
  ],
  [
    'monorepos/turborepo#debug-turborepo-cache-misses',
    'monorepos/turborepo#unexpected-cache-misses',
  ],
  [
    'monorepos/turborepo#troubleshooting-guide',
    'monorepos/turborepo#troubleshooting',
  ],
  [
    'monorepos/turborepo#using-turbo-ignore',
    'monorepos/turborepo#ignoring-unchanged-builds',
  ],
  [
    'workflow-collaboration/comments/integrations#use-the-vercel-comments-linear-integration',
    'workflow-collaboration/comments/integrations#adding-comments-to-your-issue-tracker',
  ],
  [
    'workflow-collaboration/comments/integrations#using-the-vercel-comments-jira-integration',
    'workflow-collaboration/comments/integrations#adding-comments-to-your-issue-tracker',
  ],
  [
    'workflow-collaboration/comments/integrations#using-the-vercel-comments-jira-integration',
    'workflow-collaboration/comments/integrations#adding-comments-to-your-issue-tracker',
  ],
  [
    'functions#adding-utility-files-to-the-/api-directory',
    'functions/concepts#adding-utility-files-to-the-/api-directory',
  ],
  ['functions#error-logs', 'functions#logs'],
  ['functions#local-development', 'functions/quickstart#local-development'],
  ['functions#logging-serverless-functions', 'functions#logs'],
  ['functions#advanced-usage', 'functions/concepts#advanced-usage'],
  ['functions#supported-apis', 'functions/runtimes/node-js#supported-apis'],
  ['functions#technical-details', 'functions/runtimes'],
  [
    'functions#serverless-functions-lifecycle',
    'functions/concepts#functions-lifecycle',
  ],
  [
    'functions#improving-serverless-function-performance',
    'functions/concepts#improving-serverless-function-performance',
  ],
  ['functions#cpu-calculation', 'functions/configuring-functions/memory'],
  ['functions#limits', 'functions/limitations'],
  ['functions#how-edge-functions-work', 'functions/concepts'],
  ['functions#support-for-webassembly', 'functions/wasm'],
  [
    'functions#regional-edge-function-invocation',
    'functions/configuring-functions/region',
  ],
  [
    'functions#using-a-database-with-edge-functions',
    'functions/configuring-functions/region#available-configurations',
  ],
  ['functions#logging', 'functions#logs'],
  [
    'functions/configuring-functions/memory#setting-your-default-function-memory-size',
    'functions/configuring-functions/memory#setting-your-default-function-memory-/-cpu-size',
  ],
  //usage and pricing
  ['pricing/manage-and-optimize-usage#networking', 'pricing/networking'],
  [
    'pricing/manage-and-optimize-usage#top-paths',
    'pricing/networking#top-paths',
  ],
  [
    'pricing/manage-and-optimize-usage#path-types',
    'pricing/networking#managing-top-paths',
  ],
  [
    'pricing/manage-and-optimize-usage#how-are-usage-top-paths-and-monitoring-related',
    'pricing/networking#using-top-paths-and-monitoring',
  ],
  [
    'pricing/manage-and-optimize-usage#bandwidth',
    'pricing/networking#fast-data-transfer',
  ],
  [
    'pricing/manage-and-optimize-usage#requests',
    'pricing/networking#edge-requests',
  ],
  ['pricing/manage-and-optimize-usage#data-cache', 'pricing/data-cache'],
  [
    'pricing/manage-and-optimize-usage#data-cache-overview',
    'pricing/data-cache#data-cache-overview',
  ],
  [
    'pricing/manage-and-optimize-usage#data-cache-bandwidth',
    'pricing/data-cache#managing-data-cache-bandwidth',
  ],
  [
    'pricing/manage-and-optimize-usage#data-cache-revalidations',
    'pricing/data-cache#managing-data-cache-revalidations',
  ],
  [
    'pricing/data-cache#managing-data-cache-reads',
    'pricing/data-cache#optimizing-data-cache-reads-and-writes',
  ],
  [
    'pricing/data-cache#managing-data-cache-writes',
    'pricing/data-cache#optimizing-data-cache-reads-and-writes',
  ],
  [
    'pricing/manage-and-optimize-usage#serverless-functions',
    'pricing/serverless-functions',
  ],
  [
    'pricing/manage-and-optimize-usage#invocations',
    'pricing/serverless-functions#managing-function-invocations',
  ],
  [
    'pricing/manage-and-optimize-usage#execution',
    'pricing/serverless-functions#managing-function-duration',
  ],
  [
    'pricing/manage-and-optimize-usage#throttles',
    'pricing/serverless-functions#throttles',
  ],
  [
    'pricing/manage-and-optimize-usage#edge-functions',
    'pricing/edge-functions',
  ],
  [
    'pricing/manage-and-optimize-usage#edge-functions-invocations',
    'pricing/edge-functions#managing-functions-invocations',
  ],
  [
    'pricing/manage-and-optimize-usage#execution-units',
    'pricing/edge-functions#managing-execution-units',
  ],
  [
    'pricing/manage-and-optimize-usage#cpu-time',
    'pricing/edge-functions#managing-cpu-time',
  ],
  [
    'pricing/manage-and-optimize-usage#edge-middleware',
    'pricing/edge-middleware',
  ],
  [
    'pricing/manage-and-optimize-usage#middleware-invocations',
    'pricing/edge-middleware#managing-middleware-invocations',
  ],
  [
    'pricing/manage-and-optimize-usage#edge-middleware-cpu-time',
    'pricing/edge-middleware#managing-middleware-cpu-time',
  ],
  ['pricing/manage-and-optimize-usage#edge-config', 'pricing/edge-config'],
  [
    'pricing/manage-and-optimize-usage#reads',
    'pricing/edge-config#reviewing-edge-config-reads',
  ],
  [
    'pricing/manage-and-optimize-usage#writes',
    'pricing/edge-config#managing-edge-config-writes',
  ],
  ['pricing/manage-and-optimize-usage#monitoring', 'pricing/observability'],
  [
    'pricing/manage-and-optimize-usage#monitoring-data-points',
    'pricing/observability#managing-data-points',
  ],
  ['pricing/manage-and-optimize-usage#builds', 'pricing/builds'],
  [
    'pricing/manage-and-optimize-usage#build-time',
    'pricing/builds#managing-build-time',
  ],
  [
    'pricing/manage-and-optimize-usage#number-of-builds',
    'pricing/builds#number-of-builds',
  ],
  ['pricing/manage-and-optimize-usage#artifacts', 'pricing/artifacts'],
  [
    'pricing/manage-and-optimize-usage#time-saved',
    'pricing/artifacts#time-saved',
  ],
  [
    'pricing/manage-and-optimize-usage#number-of-remote-cache-artifacts',
    'pricing/artifacts#number-of-remote-cache-artifacts',
  ],
  [
    'pricing/manage-and-optimize-usage#total-size-of-remote-cache-artifacts',
    'pricing/artifacts#managing-total-size-of-remote-cache-artifacts',
  ],
  ['pricing/manage-and-optimize-usage#postgres', 'pricing/postgres'],
  [
    'pricing/manage-and-optimize-usage#postgres-compute-time',
    'pricing/postgres#managing-postgres-compute-time',
  ],
  [
    'pricing/manage-and-optimize-usage#postgres-data-storage',
    'pricing/postgres#managing-postgres-data-storage',
  ],
  [
    'pricing/manage-and-optimize-usage#postgres-data-transfer',
    'pricing/postgres#managing-postgres-data-transfer',
  ],
  [
    'pricing/manage-and-optimize-usage#postgres-written-data',
    'pricing/postgres#managing-postgres-written-data',
  ],
  [
    'pricing/manage-and-optimize-usage#postgres-databases',
    'pricing/postgres#managing-postgres-databases',
  ],
  ['pricing/manage-and-optimize-usage#kv', 'pricing/kv'],
  [
    'pricing/manage-and-optimize-usage#kv-requests',
    'pricing/kv#managing-kv-requests',
  ],
  [
    'pricing/manage-and-optimize-usage#kv-data-transfer',
    'pricing/kv#managing-kv-data-transfer',
  ],
  [
    'pricing/manage-and-optimize-usage#kv-data-storage',
    'pricing/kv#managing-kv-data-storage',
  ],
  [
    'pricing/manage-and-optimize-usage#kv-databases',
    'pricing/kv#managing-kv-databases',
  ],
  [
    'pricing/manage-and-optimize-usage#web-analytics',
    'pricing/observability#managing-web-analytics-events',
  ],
  [
    'pricing/manage-and-optimize-usage#web-analytics-events',
    'pricing/observability#managing-web-analytics-events',
  ],
  [
    'pricing/manage-and-optimize-usage#speed-insights',
    'pricing/observability#managing-speed-insights-data-points',
  ],
  [
    'pricing/manage-and-optimize-usage#speed-insights-data-points',
    'pricing/observability#managing-speed-insights-data-points',
  ],
  [
    'pricing/manage-and-optimize-usage#image-optimization',
    'pricing/image-optimization',
  ],
  [
    'pricing/manage-and-optimize-usage#image-optimization-data-points',
    'pricing/image-optimization#source-image-optimizations',
  ],
  [
    'accounts/plans/enterprise#performance',
    'accounts/plans/enterprise#performance-and-reliability',
  ],
  [
    'accounts/plans/enterprise#security',
    'accounts/plans/enterprise#security-and-compliance',
  ],
  [
    'accounts/plans/hobby#upgrading-from-hobby-to-pro',
    'accounts/plans/hobby#general-features',
  ],
  [
    'functions/streaming#choosing-a-runtime',
    'functions/streaming#streaming-functions',
  ],
  [
    'functions/streaming#edge-runtime',
    'functions/streaming#streaming-functions',
  ],
  [
    'functions/streaming#node.js-runtime',
    'functions/streaming#streaming-functions',
  ],
  [
    'functions/streaming#streaming-signature',
    'functions/streaming#streaming-functions',
  ],
  [
    'functions/streaming#supported-serverless-streaming-frameworks',
    'functions/streaming#streaming-functions',
  ],
  [
    'functions/streaming/streaming-examples#streaming-responses-from-llms-and-ai-apis',
    'recipes/streaming-from-llm',
  ],
  [
    'functions/streaming/streaming-examples#processing-data-chunks',
    'recipes/processing-data-chunks',
  ],
  [
    'functions/streaming/streaming-examples#handling-backpressure',
    'recipes/handling-backpressure',
  ],
  [
    'functions/og-image-generation/og-image-examples#dynamic-text-generated-as-image',
    'recipes/dynamic-text-generated-as-image',
  ],
  [
    'functions/og-image-generation/og-image-examples#using-an-external-dynamic-image',
    'recipes/using-an-external-dynamic-image',
  ],
  [
    'functions/og-image-generation/og-image-examples#using-a-local-image',
    'recipes/using-local-image',
  ],
  [
    'functions/og-image-generation/og-image-examples#using-emoji-in-your-image',
    'recipes/using-emoji-in-image',
  ],
  [
    'functions/og-image-generation/og-image-examples#using-an-svg-image',
    'recipes/using-svg-image',
  ],
  [
    'functions/og-image-generation/og-image-examples#using-a-custom-font',
    'recipes/using-custom-font',
  ],
  [
    'functions/og-image-generation/og-image-examples#using-tailwind-css---experimental',
    'recipes/using-tailwind',
  ],
  [
    'functions/og-image-generation/og-image-examples#using-different-languages',
    'recipes/using-different-languages',
  ],
  [
    'functions/og-image-generation/og-image-examples#encrypting-parameters',
    'recipes/encrypting-parameters',
  ],
  [
    'functions/configuring-functions/memory#checking-your-function-memory-size-in-the-dashboard',
    'functions/configuring-functions/memory#viewing-your-function-memory-size',
  ],
  [
    'projects/domains/troubleshooting#domain-ip-addresses-and-geographic-regions',
    'projects/domains/troubleshooting#does-my-domain-ip-address-on-vercel-resolve-to-a-geographic-region',
  ],
  [
    'workflow-collaboration/feature-flags/feature-flags-pattern#feature-flags-pattern',
    'workflow-collaboration/feature-flags/feature-flags-pattern#flags-sdk-pattern',
  ],
  [
    'workflow-collaboration/feature-flags#getting-started',
    'workflow-collaboration/feature-flags/using-vercel-toolbar#view-and-override-flags-in-the-toolbar',
  ],
  [
    'workflow-collaboration/feature-flags#using-flags-in-the-toolbar',
    'workflow-collaboration/feature-flags/using-vercel-toolbar#view-and-override-flags-in-the-toolbar',
  ],
  [
    'workflow-collaboration/feature-flags/supporting-feature-flags#sharing-flag-overrides',
    'workflow-collaboration/feature-flags/using-vercel-toolbar#sharing-flag-overrides',
  ],
  [
    'workflow-collaboration/feature-flags/supporting-feature-flags#branch-based-recommendations',
    'workflow-collaboration/feature-flags/using-vercel-toolbar#branch-based-recommendations',
  ],
  [
    'workflow-collaboration/feature-flags/supporting-feature-flags#url-based-recommendations',
    'workflow-collaboration/feature-flags/using-vercel-toolbar#url-based-recommendations',
  ],
  [
    'workflow-collaboration/comments/how-comments-work#advanced-comments-management',
    'workflow-collaboration/comments/how-comments-work',
  ],
  [
    'workflow-collaboration/comments/how-comments-work#enable-or-disable-comments-for-branches',
    'workflow-collaboration/comments/how-comments-work#with-environment-variables',
  ],
  [
    'workflow-collaboration/comments/how-comments-work#enable-or-disable-comments-with-environment-variables',
    'workflow-collaboration/comments/how-comments-work#with-environment-variables',
  ],
  [
    'workflow-collaboration/comments/how-comments-work#disable-comments-temporarily',
    'workflow-collaboration/comments/how-comments-work#at-the-session-or-interface-level',
  ],
  [
    'workflow-collaboration/comments/how-comments-work#comments-on-main-or-master-branches',
    'workflow-collaboration/comments/how-comments-work#in-production-and-localhost',
  ],
  [
    'workflow-collaboration/comments/how-comments-work#using-a-content-security-policy',
    'workflow-collaboration/vercel-toolbar/managing-toolbar#using-a-content-security-policy',
  ],
  [
    'workflow-collaboration/comments/how-comments-work#using-comments-with-a-custom-alias-domain',
    'workflow-collaboration/vercel-toolbar/managing-toolbar#using-comments-with-a-custom-alias-domain',
  ],
  [
    'workflow-collaboration/comments/how-comments-work#using-comments-in-production-or-local-environments',
    'workflow-collaboration/comments/how-comments-work#in-production-and-localhost',
  ],
  [
    'functions/runtimes/node-js#node.js-version',
    'functions/runtimes/node-js/node-js-versions',
  ],
  [
    'functions/runtimes/node-js#setting-the-node.js-version-in-project-settings',
    'functions/runtimes/node-js/node-js-versions#setting-the-node.js-version-in-project-settings',
  ],
  [
    'functions/runtimes/node-js#default-and-available-versions',
    'functions/runtimes/node-js/node-js-versions#default-and-available-versions',
  ],
  [
    'functions/runtimes/node-js#version-overrides-in-package.json',
    'functions/runtimes/node-js/node-js-versions#version-overrides-in-package.json',
  ],
  [
    "functions/runtimes/node-js#checking-your-deployment's-node.js-version",
    "functions/runtimes/node-js/node-js-versions#checking-your-deployment's-node.js-version",
  ],
  [
    'functions/runtimes/node-js#advanced-node.js-usage',
    'functions/runtimes/node-js/advanced-node-configuration',
  ],
  [
    'functions/runtimes/node-js#disabling-helpers-for-node.js',
    'functions/runtimes/node-js/advanced-node-configuration#disabling-helpers-for-node.js',
  ],
  [
    'functions/runtimes/node-js#custom-build-step-for-node.js',
    'functions/runtimes/node-js/advanced-node-configuration#custom-build-step-for-node.js',
  ],
  [
    'functions/streaming#streaming-functions',
    'functions/streaming-functions#streaming-functions',
  ],
  [
    'functions/streaming#serverless-streaming-duration',
    'functions/streaming-functions#serverless-streaming-duration',
  ],
  [
    'functions/streaming#non-streaming-functions',
    'functions/streaming-functions#non-streaming-functions',
  ],
  ['functions/streaming-functions#runtime-logs', 'functions/logs#runtime-logs'],
  ['functions#logs', 'functions/logs'],
  [
    'functions#number-of-logs-per-request',
    'functions/logs#number-of-logs-per-request',
  ],
  ['functions#next.js-logs', 'functions/logs#next.js-logs'],
  [
    'functions/streaming-functions#forcing-streaming-functions',
    'functions/streaming-functions#forcing-functions-to-stream',
  ],
  [
    'integrations/install-an-integration/manage-integrations#manage-native-integrations',
    'integrations/install-an-integration/product-integration#manage-native-integrations',
  ],
  [
    'integrations/install-an-integration/manage-integrations#manage-connectable-accounts',
    'integrations/install-an-integration/add-a-connectable-account#manage-connectable-accounts',
  ],
  [
    'integrations/install-an-integration/manage-integrations#understand-permissions',
    'integrations/install-an-integration/manage-integrations-reference',
  ],
  [
    'integrations/install-an-integration/manage-integrations#confirming-permission-changes',
    'integrations/install-an-integration/manage-integrations-reference#confirming-permission-changes',
  ],
  [
    'integrations/install-an-integration/manage-integrations#manage-access',
    'integrations/install-an-integration/manage-integrations-reference#manage-project-access',
  ],
  [
    'integrations/install-an-integration/manage-integrations#manage-products',
    'integrations/install-an-integration/product-integration#manage-products',
  ],
  [
    'integrations/install-an-integration/manage-integrations#uninstall-an-integration',
    'integrations/install-an-integration/product-integration#uninstall-an-integration',
  ],
  [
    'docs/integrations/install-an-integration/manage-integrations#disabled-integrations',
    'integrations/install-an-integration/manage-integrations-reference#disabled-integrations',
  ],
];
