import { generateRedirects } from '../utils';

export function IntegrationRedirects(): void {
  generateRedirects({
    location: { pathname: location.pathname, hash: location.hash },
    rootPrefix: '/docs/integrations',
    sections: [],
    exceptions: [
      [
        'integrations/install-an-integration#external',
        'integrations/install-an-integration#find-and-use-integrations',
      ],
      [
        'integrations/install-an-integration#deploy-button',
        'integrations/install-an-integration#find-and-use-integrations',
      ],
      [
        'integrations/install-an-integration#marketplace',
        'integrations/install-an-integration#find-and-use-integrations',
      ],
      [
        'integrations/install-an-integration#uninstall-an-integration',
        'integrations/install-an-integration#uninstall',
      ],
      [
        'integrations/using-an-integration/permissions#confirming-permission-changes',
        'integrations/install-an-integration#confirming-permission-changes',
      ],
      ['integrations#introduction/vercel-integrations', '/integrations'],
      [
        'integrations#introduction/why-build-or-use-an-integration',
        'integrations#why-build-or-use-an-integration',
      ],
      [
        'integrations#introduction/what-makes-an-integration',
        'integrations#how-do-vercel-integration-installations-work',
      ],
      [
        'integrations#creating-your-integration',
        'integrations/create-integration',
      ],
      [
        'integrations#creating-your-integration/integration-settings',
        'integrations/create-integration#integration-settings',
      ],
      [
        'integrations#creating-your-integration/integration-settings/redirect-url',
        'integrations/create-integration#redirect-url',
      ],
      [
        'integrations#creating-your-integration/integration-settings/webhook-url',
        'integrations/create-integration#webhook-url',
      ],
      [
        'integrations#creating-your-integration/integration-settings/configuration-url',
        'integrations/create-integration#configuration-url',
      ],
      [
        'integrations#creating-your-integration/view-created-integration',
        'integrations/create-integration#view-created-integration',
      ],
      [
        'integrations#creating-your-integration/integration-review-guidelines',
        'integrations/submit-integration#review-guidelines',
      ],
      [
        'integrations#creating-your-integration/integration-review-guidelines/integration-technical-specifications',
        'integrations/submit-integration#integration-technical-specifications',
      ],
      [
        'integrations#creating-your-integration/integration-review-guidelines/integration-listing-design-specifications',
        'integrations/submit-integration#integration-listing-design-and-content-specs',
      ],
      [
        'integrations#creating-your-integration/integration-review-guidelines/integration-listing-content-specifications',
        'integrations/submit-integration#integration-listing-design-and-content-specs',
      ],
      [
        'integrations#creating-your-integration/submitting-for-review',
        'integrations/submit-integration',
      ],
      [
        'integrations#installing-your-integration',
        'integrations/install-integration',
      ],
      [
        'integrations#installing-your-integration/ways-your-integration-can-be-installed',
        'integrations/install-integration#ways-to-install-an-integration',
      ],
      [
        'integrations#installing-your-integration/ways-your-integration-can-be-installed/marketplace',
        'integrations/install-integration#marketplace',
      ],
      [
        'integrations#installing-your-integration/ways-your-integration-can-be-installed/marketplace/if-already-installed-in-the-selected-scope',
        'integrations/install-integration#marketplace',
      ],
      [
        'integrations#installing-your-integration/ways-your-integration-can-be-installed/external',
        'integrations/install-integration#external',
      ],
      [
        'integrations#installing-your-integration/ways-your-integration-can-be-installed/external/if-already-installed-in-the-selected-scope',
        'integrations/install-integration#external',
      ],
      [
        'integrations#installing-your-integration/ways-your-integration-can-be-installed/deploy-button',
        'integrations/install-integration#deploy-button',
      ],
      [
        'integrations#installing-your-integration/ways-your-integration-can-be-installed/deploy-button/if-already-installed-in-the-selected-scope',
        'integrations/install-integration#deploy-button',
      ],
      [
        'integrations#installing-your-integration/managing-installed-integrations',
        'integrations/install-an-integration#manage-integrations-reference',
      ],
      [
        'integrations#installing-your-integration/managing-installed-integrations/managing-access',
        'integrations/install-an-integration#manage-integrations-reference',
      ],
      [
        'integrations#installing-your-integration/managing-installed-integrations/uninstalling-an-integration',
        'integrations#installing/uninstalling-an-integration',
      ],
      ['integrations#using-the-vercel-api', 'integrations/vercel-api-overview'],
      [
        'integrations#using-the-vercel-api/getting-an-access-token',
        'integrations/vercel-api-overview#using-an-access-token',
      ],
      [
        'integrations#using-the-vercel-api/getting-an-access-token/exchanging-the-code-for-an-access-token',
        'integrations#using-the-vercel-api/exchange-code-for-access-token',
      ],
      [
        'integrations#using-the-vercel-api/getting-an-access-token/using-access-token',
        'integrations/vercel-api-overview#using-an-access-token',
      ],
      [
        'integrations#using-the-vercel-api/getting-an-access-token/knowing-the-scope-of-your-access-token',
        'integrations/vercel-api-overview#scope-of-your-access-token',
      ],
      [
        'integrations#using-the-vercel-api/creating-checks',
        '/integrations/checks-overview',
      ],
      [
        'integrations/checks-overview#building-your-own-checks',
        'integrations/checks-overview#building-your-own-checks-integration',
      ],
      [
        'integrations/checks-overview/creating-checks#check-results',
        'integrations/checks-overview/creating-checks#rich-results',
      ],
      [
        'integrations/checks-overview#check-results',
        'integrations/checks-overview/creating-checks#rich-results',
      ],
      [
        'integrations/checks-overview/checks-api#status-and-conclusion',
        'integrations/checks-overview/creating-checks#status-and-conclusion',
      ],
      [
        'integrations/checks-overview/checks-api#output',
        'integrations/checks-overview/creating-checks#output',
      ],
      [
        'integrations/checks-overview/creating-checks#supported-services',
        'integrations/checks-overview',
      ],
      ['integrations#webhooks', 'integrations/webhooks-overview'],
      [
        'integrations#webhooks/setting-up',
        'integrations/webhooks-overview#setting-up-webhooks',
      ],
      ['integrations#webhooks/events', 'integrations/webhooks-overview#events'],
      [
        'integrations#webhooks/events/deployment',
        'webhooks#introduction/supported-event-types/deployment',
      ],
      [
        'integrations#webhooks/events/deployment-ready',
        'webhooks#introduction/supported-event-types/deployment-ready',
      ],
      [
        'integrations#webhooks/events/deployment-prepared',
        'webhooks#introduction/supported-event-types/deployment-prepared',
      ],
      [
        'integrations#webhooks/events/deployment-error',
        'webhooks#introduction/supported-event-types/deployment-error',
      ],
      [
        'integrations#webhooks/events/deployment-check-rerequested',
        'webhooks#introduction/supported-event-types/deployment-check-rerequested',
      ],
      [
        'integrations#webhooks/events/deployment-checks-completed',
        'webhooks#introduction/supported-event-types/deployment-checks-completed',
      ],
      [
        'integrations#webhooks/events/deployment-checks-completed',
        'webhooks#introduction/legacy-event-types/deployment-checks-completed',
      ],
      [
        'integrations#webhooks/events/project-created',
        'webhooks#introduction/supported-event-types/project-created',
      ],
      [
        'integrations#webhooks/events/project-removed',
        'webhooks#introduction/supported-event-types/project-removed',
      ],
      [
        'integrations#webhooks/events/integration-configuration-removed',
        'webhooks#introduction/supported-event-types/integration-configuration-removed',
      ],
      [
        'integrations#webhooks/events/integration-configuration-permission-updated',
        'webhooks#introduction/supported-event-types/integration-configuration-permission-upgraded',
      ],
      [
        'integrations#webhooks/events/domain-created',
        'webhooks#introduction/supported-event-types/domain-created',
      ],
      [
        'integrations#webhooks/securing-webhooks',
        'webhooks#introduction/securing-webhooks',
      ],
      [
        'integrations#webhooks/http-response',
        'webhooks#introduction/http-response',
      ],
      [
        'integrations#webhooks/delivery-attempts-and-retries',
        'webhooks#introduction/delivery-attempts-and-retries',
      ],
      [
        'integrations#upgrading-your-integration/use-generic-webhooks-instead-of-webhooks-ap-is-and-delete-hooks',
        'integrations#upgrading-your-integration/use-generic-webhooks',
      ],
      [
        'integrations#upgrading-your-integration/use-the-external-flow-instead-of-o-auth2',
        'integrations#upgrading-your-integration/use-external-flow',
      ],
      [
        'integrations#upgrading-your-integration/use-your-own-ui-instead-of-ui-hooks',
        'integrations#upgrading-your-integration/use-your-own-ui',
      ],
      [
        'integrations#upgrading-your-integration/use-current-project-id-in-the-deploy-button-flow',
        'integrations#upgrading-your-integration/current-project-id-in-deploy-button',
      ],
      [
        'integrations#upgrading-your-integration/assume-single-installation-per-scope',
        'integrations#upgrading-your-integration/single-installation-per-scope',
      ],
      [
        'integrations#upgrading-your-integration/use-latest-api-version-for-adding-environment-variables-to-projects',
        'integrations#upgrading-your-integration/latest-api-for-environment-variables',
      ],
      [
        'integrations#creating-your-integration/best-practices',
        'integrations#review-guidelines',
      ],
      [
        'integrations#review-guidelines/design-specs/assets-dimensions',
        'integrations#review-guidelines/design-specs/asset-dimensions',
      ],
      [
        'integrations#installing',
        'integrations/using-an-integration/install-integration',
      ],
      [
        'integrations#installing/query-parameters',
        'integrations/create-integration#redirect-url',
      ],
      [
        'integrations#installing/query-parameters/marketplace',
        'integrations/create-integration#marketplace',
      ],
      [
        'integrations#installing/query-parameters/external',
        'integrations/create-integration#external',
      ],
      [
        'integrations#installing/query-parameters/deploy-button',
        'integrations/create-integration#deploy-button',
      ],
      [
        'integrations#installing/uninstalling-an-integration',
        'integrations/using-an-integration/install-integration#uninstall-an-integration',
      ],
      [
        'integrations#review-guidelines',
        'integrations/create-integration/submit-integration',
      ],
      [
        'integrations#review-guidelines/technical-specs',
        'integrations/create-integration/submit-integration#technical-specs',
      ],
      [
        'integrations#review-guidelines/technical-specs/installation-flow-required',
        'integrations/create-integration/submit-integration#installation-flow-(required)',
      ],
      [
        'integrations#review-guidelines/design-specs',
        'integrations/create-integration/submit-integration#design-specs',
      ],
      [
        'integrations#review-guidelines/design-specs/icon-required',
        'integrations/create-integration/submit-integration#icon-required',
      ],
      [
        'integrations#review-guidelines/design-specs/gallery-images-required',
        'integrations/create-integration/submit-integration#gallery-images-required',
      ],
      [
        'integrations#review-guidelines/design-specs/asset-dimensions',
        'integrations/create-integration/submit-integration#asset-dimensions',
      ],
      [
        'integrations#review-guidelines/content-specs',
        'integrations/create-integration/submit-integration#content-specs',
      ],
      [
        'integrations#using-the-vercel-api',
        'integrations/vercel-api-overview/vercel-api-reference#',
      ],
      [
        'integrations#using-the-vercel-api/exchange-code-for-access-token',
        'integrations/vercel-api-overview/vercel-api-reference#exchange-code-for-access-token',
      ],
      [
        'integrations#using-the-vercel-api/interacting-with-configurations',
        'integrations/vercel-api-overview/vercel-api-reference#interacting-with-configurations',
      ],
      [
        'integrations#using-the-vercel-api/interacting-with-configurations/disabled-integration-configurations',
        'integrations/vercel-api-overview/vercel-api-reference#disabled-integration-configurations',
      ],
      [
        'integrations#using-the-vercel-api/interacting-with-vercel-projects',
        'integrations/vercel-api-overview/vercel-api-reference#interacting-with-vercel-projects',
      ],
      [
        'integrations#using-the-vercel-api/interacting-with-vercel-projects/modifying-environment-variables-on-a-project',
        'integrations/vercel-api-overview/vercel-api-reference#modifying-environment-variables-on-a-project',
      ],
      [
        'integrations#using-the-vercel-api/interacting-with-vercel-projects/using-the-vercel-api',
        'integrations/vercel-api-overview/vercel-api-reference#using-the-vercel-api',
      ],
      [
        'integration#using-the-vercel-api/scopes',
        'integrations/vercel-api-overview/vercel-api-reference#scopes',
      ],
      [
        'integration#using-the-vercel-api/scopes/integration-configuration',
        'integrations/vercel-api-overview/vercel-api-reference#using-the-vercel-api/scopes/integration-configuration',
      ],
      [
        'integration#using-the-vercel-api/scopes/deployments',
        'integrations/vercel-api-overview/vercel-api-reference#using-the-vercel-api/scopes/deployments',
      ],
      [
        'integration#using-the-vercel-api/scopes/deployment-checks',
        'integrations/vercel-api-overview/vercel-api-reference#using-the-vercel-api/scopes/deployment-checks',
      ],
      [
        'integration#using-the-vercel-api/scopes/projects',
        'integrations/vercel-api-overview/vercel-api-reference#using-the-vercel-api/scopes/projects',
      ],
      [
        'integration#using-the-vercel-api/scopes/project-environmental-variables',
        'integrations/vercel-api-overview/vercel-api-reference#using-the-vercel-api/scopes/project-environmental-variables',
      ],
      [
        'integration#using-the-vercel-api/scopes/teams',
        'integrations/vercel-api-overview/vercel-api-reference#using-the-vercel-api/scopes/teams',
      ],
      [
        'integration#using-the-vercel-api/scopes/user',
        'integrations/vercel-api-overview/vercel-api-reference#using-the-vercel-api/scopes/user',
      ],
      [
        'integration#using-the-vercel-api/scopes/log-drains',
        'integrations/vercel-api-overview/vercel-api-reference#using-the-vercel-api/scopes/log-drains',
      ],
      [
        'integration#using-the-vercel-api/scopes/domain',
        'integrations/vercel-api-overview/vercel-api-reference#using-the-vercel-api/scopes/domain',
      ],
      [
        'integration#using-the-vercel-api/scopes/updating-scopes',
        'integrations/vercel-api-overview/vercel-api-reference#updating-scopes',
      ],
      [
        'integration#using-the-vercel-api/scopes/confirmed-scope-changes',
        'integrations/vercel-api-overview/vercel-api-reference#confirmed-scope-changes',
      ],
      [
        'integration#using-the-vercel-api/common-errors',
        'integrations/vercel-api-overview/vercel-api-reference#common-errors',
      ],
      [
        'integration#using-the-vercel-api/common-errors/cors-issues',
        'integrations/vercel-api-overview/vercel-api-reference#cors-issues',
      ],
      [
        'integration#using-the-vercel-api/common-errors/403-forbidden-responses',
        'integrations/vercel-api-overview/vercel-api-reference#cors-issues',
      ],
      [
        'integration#using-the-checks-api',
        'integrations/working-with-checks/checks-api',
      ],
      [
        'integration#using-the-checks-api/creating-checks',
        'integrations/working-with-checks/creating-checks',
      ],
      [
        'integration#using-the-checks-api/creating-checks/rerunning-checks',
        'integrations/working-with-checks/creating-checks#rerunning-checks',
      ],
      [
        'integration#upgrading-your-integration',
        'integrations/create-integration/upgrade-integration',
      ],
      /** Deploy button redirects - because we have a nextconfig redirect from as ['deploy-button', 'integrations/deploy-button'] and this happens before hash redirects, we don't need to add any hash redirects that will map exactly from 'deploy-button' to 'integrations/deploy-button' such as 'deploy-button#snippets to integrations/deploy-button#snippets. However, anything that does not follow this pattern will have to be redirected from 'integrations/deploy-button/path+hash' to 'integrations/deploy-button/newpath+newhash' since a redirection to 'integrations/' has already happened */
      /** To test any of the redirects, remove 'integrations/' from the from path */
      [
        'integrations/deploy-button#introduction/what-s-a-deploy-button',
        'integrations/deploy-button',
      ],
      [
        'integrations/deploy-button#source',
        'integrations/deploy-button/source',
      ],
      [
        'integrations/deploy-button#source/repository-url',
        'integrations/deploy-button/source#repository-url',
      ],
      [
        'integrations/deploy-button#source/project-name',
        'integrations/deploy-button/source#project-name',
      ],
      [
        'integrations/deploy-button#source/repository-name',
        'integrations/deploy-button/source#repository-name',
      ],
      ['integrations/deploy-button#demo', 'integrations/deploy-button/demo'],
      [
        'integrations/deploy-button#demo/demo-title',
        'integrations/deploy-button/demo#demo-title',
      ],
      [
        'integrations/deploy-button#demo/demo-description',
        'integrations/deploy-button/demo#demo-description',
      ],
      [
        'integrations/deploy-button#demo/demo-url',
        'integrations/deploy-button/demo#demo-url',
      ],
      [
        'integrations/deploy-button#demo/demo-image',
        'integrations/deploy-button/demo#demo-image',
      ],
      [
        'integrations/deploy-button#build-settings',
        'integrations/deploy-button/build-settings',
      ],
      [
        'integrations/deploy-button#build-settings/build-command',
        'integrations/deploy-button/build-settings#build-command',
      ],
      [
        'integrations/deploy-button#build-settings/install-command',
        'integrations/deploy-button/build-settings#install-command',
      ],
      [
        'integrations/deploy-button#build-settings/development-command',
        'integrations/deploy-button/build-settings#development-command',
      ],
      [
        'integrations/deploy-button#build-settings/ignored-build-command',
        'integrations/deploy-button/build-settings#ignored-build-command',
      ],
      [
        'integrations/deploy-button#build-settings/root-directory',
        'integrations/deploy-button/build-settings#root-directory',
      ],
      [
        'integrations/deploy-button#build-settings/output-directory',
        'integrations/deploy-button/build-settings#output-directory',
      ],
      [
        'integrations/deploy-button#environment-variables',
        'integrations/deploy-button/environment-variables',
      ],
      [
        'integrations/deploy-button#environment-variables/required-environment-variables',
        'integrations/deploy-button/environment-variables#required-environment-variables',
      ],
      [
        'integrations/deploy-button#environment-variables/environment-variables-description',
        'integrations/deploy-button/environment-variables#environment-variables-description',
      ],
      [
        'integrations/deploy-button#environment-variables/environment-variables-link',
        'integrations/deploy-button/environment-variables#environment-variables-link',
      ],
      [
        'integrations/deploy-button#integrations',
        'integrations/deploy-button/integrations',
      ],
      [
        'integrations/deploy-button#integrations/required-integrations',
        'integrations/deploy-button/integrations#required-integrations',
      ],
      [
        'integrations/deploy-button#integrations/skippable-integrations',
        'integrations/deploy-button/integrations#skippable-integrations',
      ],
      [
        'integrations/deploy-button#callback',
        'integrations/deploy-button/callbacks',
      ],
      [
        'integrations/deploy-button#callback/redirect-url',
        'integrations/deploy-button/callback#redirect-url',
      ],
      [
        'integrations/deploy-button#callback/redirect-url/callback-parameters',
        'integrations/deploy-button/callback#callback-parameters',
      ],
      [
        'integrations/deploy-button#callback/developer-id',
        'integrations/deploy-button/callback#developer-id',
      ],
      [
        'integrations/deploy-button#callback/external-id',
        'integrations/deploy-button/callback#external-id',
      ],
      [
        'integrations/deploy-button#callback/deploy-hook',
        'integrations/deploy-button/callback#deploy-hook',
      ],
      //webhooks redirects
      [
        'webhooks#introduction/supported-event-types',
        'integrations/working-with-webhooks/webhooks-api#supported-event-types',
      ],
      [
        'webhooks#introduction/securing-webhooks',
        'integrations/working-with-webhooks/webhooks-api#securing-webhooks',
      ],
      [
        'webhooks#introduction/http-response',
        'integrations/working-with-webhooks/webhooks-api#http-response',
      ],
      [
        'webhooks#introduction/delivery-attempts-and-retries',
        'integrations/working-with-webhooks/webhooks-api#delivery-attempts-and-retries',
      ],

      // Checks Docs redirects
      [
        'rest-api#introduction/api-basics/status-and-conclusion',
        'checks#introduction/status-and-conclusion',
      ],
      [
        'rest-api#introduction/api-basics/output',
        'checks#introduction/status-and-conclusion/output',
      ],
      [
        'docs/checks#introduction/status-and-conclusion',
        'integrations/working-with-checks/checks-api#status-and-conclusion',
      ],
      [
        'checks#introduction/status-and-conclusion/output',
        'integrations/working-with-checks/checks-api#output',
      ],
      [
        'integrations/log-drains-overview#configure-a-log-drain',
        'observability/log-drains-overview/log-drains#configure-a-log-drain',
      ],
      [
        'integrations/log-drains-overview#removing-a-log-drain',
        'observability/log-drains-overview/log-drains#removing-a-log-drain',
      ],
      [
        'integrations/log-drains-overview#integration-log-drains',
        'observability/log-drains-overview/log-drains#integration-log-drains',
      ],
      [
        'integrations/log-drains-overview/log-drains-reference#json-log-drains',
        'observability/log-drains-overview/log-drains-reference#json-log-drains',
      ],
      [
        'integrations/log-drains-overview/log-drains-reference#ndjson-log-drains',
        'observability/log-drains-overview/log-drains-reference#ndjson-log-drains',
      ],
      [
        'integrations/log-drains-overview/log-drains-reference#syslog-drain',
        'observability/log-drains-overview/log-drains-reference#syslog-drain',
      ],
      [
        'integrations/log-drains-overview/log-drains-reference#secure-log-drains',
        'observability/log-drains-overview/log-drains-reference#secure-log-drains',
      ],
      [
        'integrations/log-drains-overview/log-drains-reference#log-sources',
        'observability/log-drains-overview/log-drains-reference#log-sources',
      ],
      [
        'integrations#creating-your-integration/best-practices',
        'integrations#creating-your-integration/integration-review-guidelines',
      ],
      [
        'integrations/create-integration/submit-integration#technical-specs',
        'integrations/create-integration/submit-integration',
      ],
      [
        'integrations/create-integration/submit-integration#installation-flow-required',
        'integrations/create-integration#installation-flow',
      ],
      [
        'integrations/create-integration/submit-integration#design-specs',
        'integrations/create-integration/submit-integration#logo',
      ],
      [
        'integrations/create-integration/submit-integration#icon-required',
        'integrations/create-integration/submit-integration#logo',
      ],
      [
        'integrations/create-integration/submit-integration#gallery-images-required',
        'integrations/create-integration/submit-integration#feature-media',
      ],
      [
        'integrations/create-integration/submit-integration#asset-dimensions',
        'integrations/create-integration/submit-integration#feature-media',
      ],
      [
        'integrations/create-integration/submit-integration#content-specs',
        'integrations/create-integration/submit-integration',
      ],
      [
        'integrations/create-integration#integration-profile-settings',
        'integrations/create-integration/submit-integration',
      ],
      [
        'integrations/create-integration#integration-settings',
        'integrations/create-integration/submit-integration',
      ],
      [
        'integrations/create-integration#redirect-url',
        'integrations/create-integration/submit-integration#redirect-url',
      ],
      [
        'integrations/create-integration#marketplace',
        'integrations/create-integration/submit-integration#marketplace-installation-flow',
      ],
      [
        'integrations/create-integration#external',
        'integrations/create-integration/submit-integration#external-installation-flow',
      ],
      [
        'integrations/create-integration#deploy-button',
        'integrations/create-integration/submit-integration#deploy-button-installation-flow',
      ],
      [
        'integrations/create-integration#api-scopes',
        'integrations/create-integration/submit-integration#api-scopes',
      ],
      [
        'integrations/create-integration#webhook-url',
        'integrations/create-integration/submit-integration#webhook-url',
      ],
      [
        'integrations/create-integration#integration-vs.-starter-kit',
        'integrations/create-integration',
      ],
    ],
  });
}
