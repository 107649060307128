import { RestAPIRedirects } from './docs/rest-api';
import { MiscellaneousRedirects } from './docs/miscellaneous';
import { BuildOutputRedirects } from './docs/build-output';
//import { ErrorsRedirects } from './docs/errors';
import { IntegrationRedirects } from './docs/integrations';
import { CLIRedirects } from './docs/cli';
import { SpeedInsightsRedirects } from './docs/speed-insights';
import { IsrRedirects } from './docs/incremental-static-regeneration';

export function handleDocsHashRedirects() {
  /**
 This file has the redirects for all paths under `/docs`
 If you add a new file for handling a new from location, don't forget to add the condition here and import the function
 */
  if (location.pathname.startsWith('/docs/rest-api')) {
    RestAPIRedirects();
    return;
  }
  if (location.pathname.startsWith('/docs/build-output-api')) {
    BuildOutputRedirects();
    return;
  }
  /** Disabling this due to https://vercel.slack.com/archives/C02F56A54LU/p1711547826225569
   * We should follow up with fix for the errors hash redirects function
  if (location.pathname.startsWith('/docs/errors')) {
    ErrorsRedirects();
    return;
  }
   */
  if (location.pathname.startsWith('/docs/cli')) {
    CLIRedirects();
    return;
  }
  if (location.pathname.startsWith('/docs/integrations')) {
    IntegrationRedirects();
    return;
  }
  if (location.pathname.startsWith('/docs/speed-insights')) {
    SpeedInsightsRedirects();
    return;
  }
  if (
    location.pathname.startsWith(
      '/docs/incremental-static-regeneration/overview',
    )
  ) {
    IsrRedirects();
    return;
  }
  //For redirects that include multiple prefixes in the from path such as '/docs/integrations' and '/docs/webhooks', use the catch all below
  MiscellaneousRedirects();
}
