import { usePathname } from 'next/navigation';
import type { JSX } from 'react';
import { primaryNavigation } from '@pyra/docs-shared/data/navigation/primary';
import { documentationObject } from '@pyra/docs-shared/data/navigation/documentation/index';
import { getSecondaryNavigation } from '@pyra/docs-shared/data/navigation/utils';
import type { SideBarItem } from '@pyra/docs-shared/data/navigation/types';
import { Navigation } from '../navigation';

export function SideNav(): JSX.Element {
  let navigationData: SideBarItem[] | undefined;
  const path = usePathname() as string;
  const secondaryNavigation = getSecondaryNavigation(primaryNavigation, path);
  if (
    secondaryNavigation.currentItem &&
    'key' in secondaryNavigation.currentItem &&
    secondaryNavigation.currentItem.key
  ) {
    navigationData =
      documentationObject[secondaryNavigation.currentItem.key].posts;
  } else {
    const navigationKeys = Object.keys(documentationObject);
    navigationData = documentationObject[navigationKeys[0]].posts;
  }
  return (
    <>
      {navigationData !== undefined && navigationData.length > 0 && (
        <Navigation alwaysShowNav dynamicMenu={navigationData} path={path} />
      )}
    </>
  );
}
