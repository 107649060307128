'use client';

import type { FocusEventHandler } from 'react';
import { ChevronDown } from 'geist/icons';
import { Badge } from 'geist/components';
import { Link } from '@pyra/multi-zone/link';
import { tid } from '@vercel/geist-test-utils';
import {
  analytics,
  AnalyticsEvent,
} from '@vercel/site-analytics/vercel-client';
import { isItemSelected } from '@pyra/docs-shared/data/navigation/utils';
import type { SideBarItem } from '@pyra/docs-shared/data/navigation/types';
import { Code } from '#/app/components/text/code';

interface NavTreeProps {
  data: SideBarItem[];
  isRoot: boolean;
  onItemFocus: (el: HTMLButtonElement) => void;
  openedItems: string[];
  path: string;
  toggleItem: (name: string, state?: boolean) => void;
}

export function NavTree({
  data,
  path,
  onItemFocus,
  openedItems,
  toggleItem,
  isRoot,
}: NavTreeProps): JSX.Element {
  function trackLink(name: string): void {
    analytics.track(AnalyticsEvent.CLICK_EVENT, {
      click_name: 'docs-sidebar-item_clicked',
      click_value: name,
    });
  }

  const handleFocus: FocusEventHandler<
    HTMLButtonElement | HTMLAnchorElement
  > = (event) => {
    const activeElement = event.target;
    const activeParent = activeElement.closest(
      'li:has([aria-controls*=nav-tree][aria-expanded="true"])',
    );
    const trigger = activeElement.matches(
      '[aria-controls*=nav-tree][aria-expanded="true"]',
    )
      ? activeElement
      : activeParent?.querySelector('button');
    onItemFocus(trigger as HTMLButtonElement);
  };

  function renderLinkContent(item: SideBarItem): JSX.Element {
    return (
      <>
        {item.isCode ? <Code>{item.name}</Code> : <span>{item.name}</span>}
        {item.badge ? (
          <Badge
            className="ml-1 mr-2"
            size="sm"
            variant={item.badgeStyle || 'blue-subtle'}
          >
            {item.badge}
          </Badge>
        ) : null}
      </>
    );
  }

  return (
    <>
      {data.map((item, i) => {
        const isSelected = isItemSelected(item, path);
        const isExpanded = openedItems.includes(item.href);
        const sub = `nav-tree-${item.name.toLowerCase().replace(/\s+/g, '-')}-menu`;
        return (
          <li
            className={`${isRoot ? 'pl-0' : 'pl-[17px]'} relative flex w-full select-none flex-col items-start text-[13px] leading-[20px] text-[var(--accents-6)] transition-[background] ${isSelected && !isRoot ? 'before:absolute before:inset-0 before:border-0 before:border-l-[1px] before:border-solid before:border-[var(--geist-foreground)] before:content-[""]' : ''}`}
            key={item.name}
          >
            <Link
              aria-current={isSelected ? 'page' : 'false'}
              className={`${isSelected ? 'font-[600]' : 'font-normal'} has-[+button:hover]:text-[var(--geist-foreground)] flex h-full w-full justify-between py-[8px] pr-4 hover:text-[var(--geist-foreground)]`}
              data-testid={tid(
                'sidenav',
                isRoot ? 'root' : 'child',
                i.toString(),
              )}
              href={item.href}
              onClick={() => trackLink(item.name)}
              onFocus={handleFocus}
            >
              {renderLinkContent(item)}
            </Link>
            {item.posts && item.posts.length > 0 && !item.hideChildrenSide ? (
              <button
                aria-controls={sub}
                aria-expanded={isExpanded ? 'true' : 'false'}
                className="group absolute right-0 top-0 z-10 grid aspect-square h-[36px] cursor-pointer place-items-end content-center border-0 bg-transparent p-0 outline-[var(--ds-focus-color)]"
                data-name={item.href}
                onClick={() => toggleItem(item.href)}
                onFocus={handleFocus}
                type="button"
              >
                <ChevronDown
                  className="group-[&[aria-expanded=true]]:rotate-180  motion-safe:transition-transform"
                  size={16}
                />
              </button>
            ) : null}
            {item.posts &&
            item.posts.length > 0 &&
            !item.hideChildrenSide &&
            isExpanded ? (
              <ul
                className="relative flex w-full list-none flex-col p-0 before:absolute before:inset-0 before:border-0 before:border-l-[1px] before:border-solid before:border-[var(--accents-2)] before:content-['']"
                id={sub}
              >
                <NavTree
                  data={item.posts}
                  isRoot={false}
                  onItemFocus={onItemFocus}
                  openedItems={openedItems}
                  path={path}
                  toggleItem={toggleItem}
                />
              </ul>
            ) : null}
          </li>
        );
      })}
    </>
  );
}
