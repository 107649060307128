export function RestAPIRedirects() {
  const generalPrefix = '/docs/rest-api';
  const staticRedirects = [
    ['#integrations/log-drains', '/endpoints#log-drains'],
    [
      '#integrations/log-drains/retrieves-a-list-of-log-drains',
      '/endpoints/logDrains#retrieves-a-list-of-integration-log-drains',
    ],
    [
      '#integrations/log-drains/creates-a-new-log-drain',
      '/endpoints/logDrains#creates-a-new-integration-log-drain',
    ],
    [
      '#integrations/log-drains/deletes-the-log-drain-with-the-provided-id',
      '/endpoints/logDrains#deletes-the-integration-log-drain-with-the-provided-id',
    ],
    [
      '/vercel-api-integrations#using-an-access-token',
      '/vercel-api-integrations#create-an-access-token',
    ],
    [
      '/vercel-api-integrations#scope-of-your-access-token',
      '/vercel-api-integrations#interacting-with-teams',
    ],
  ];
  const match = location.pathname + location.hash;
  const sections = ['endpoints', 'errors', 'interfaces'];
  const hasSections = sections.filter((section) =>
    match.includes(`#${section}`),
  );
  if (match.includes('#introduction/')) {
    const pathNoIntro = match.replace('#introduction/', '#/');
    const pathItems = pathNoIntro.split('/');
    if (pathItems.length <= 4) {
      const newLoc = match.replace('#introduction/', '#');
      document.location = newLoc;
      return;
    }
    pathItems.splice(3, 1);
    const newLoc = `${pathItems.slice(0, 3).join('/')}${pathItems
      .slice(3, pathItems.length)
      .join('')}`;
    document.location = newLoc;
    return;
  }
  if (
    match.includes('#integrations/') ||
    match.includes('/vercel-api-integrations')
  ) {
    for (let index = 0; index < staticRedirects.length; ++index) {
      const item = staticRedirects[index];
      if (`${generalPrefix}${item[0]}` === match) {
        document.location = `${generalPrefix}${item[1]}`;
        return;
      }
    }
  }
  if (hasSections.length === 1) {
    const pathFix = match.replace(`#${hasSections[0]}`, `/${hasSections[0]}`);
    const pathItems = pathFix.split('/');
    if (pathItems.length === 4) {
      const newLoc = pathFix;
      document.location = newLoc;
      return;
    }
    if (pathItems.length > 4) {
      const newLoc = `${generalPrefix}/${hasSections[0]}#${
        hasSections[0] === 'interfaces'
          ? pathItems[pathItems.length - 1].replace('-', '')
          : pathItems[pathItems.length - 1]
      }`;
      document.location = newLoc;
    }
  }
}
