import { useEffect, useState } from 'react';
import { ArrowUpCircle as IconArrowUpCircle } from 'geist/icons';

const headerHeight = 80;
export function BackToTop({
  size = 20,
  wrap = false,
  showText = false,
}: {
  size?: number;
  wrap?: boolean;
  showText?: boolean;
}): JSX.Element {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const checkScrollTop = (): void => {
      setShowScroll(window.scrollY > headerHeight);
    };
    window.addEventListener('scroll', checkScrollTop);
    return (): void => window.removeEventListener('scroll', checkScrollTop);
  }, [setShowScroll]);

  return (
    <>
      {showScroll ? (
        <a
          className={`back-to-top animate-[fade-in_0.25s] ${wrap ? 'ml-[8px]' : ''} flex h-[40px] cursor-pointer items-center gap-[8px] px-[8px] text-[14px] text-[var(--accents-5)] no-underline transition-colors hover:text-[var(--accents-8)]`}
          href={`#${document.title.toLowerCase().replace(/\s+/g, '-')}`}
          tabIndex={0}
        >
          <IconArrowUpCircle size={size} />
          {showText ? `Back to top` : null}
        </a>
      ) : null}
    </>
  );
}
