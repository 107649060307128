'use client';
import { track } from '@vercel/analytics';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

export function VaScrollTrack(): null {
  const pathname = usePathname();
  useEffect(() => {
    let quarters = 0;
    function onScroll(): void {
      const scrollHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      const quarterHeight = scrollHeight / 4;
      const scrollDistance =
        window.scrollY ||
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (document.documentElement || document.body.parentNode || document.body)
          .scrollTop;
      const divisible = Math.trunc(scrollDistance / quarterHeight);
      if (quarters < divisible && divisible !== Infinity) {
        const scrollPercent = divisible * 25;
        track('Scroll Depth', {
          percent: scrollPercent,
          pathname,
        });
        quarters++;
      }
    }
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, [pathname]);

  return null;
}
