'use client';
import { useEffect } from 'react';
import { handleGuidesHashRedirects } from '#/lib/redirects/hash/guides';
import { handleDocsHashRedirects } from '#/lib/redirects/hash/docs';

export function HashRedirects({ rootpath }: { rootpath: string }): null {
  useEffect(() => {
    if (rootpath === '/docs') {
      handleDocsHashRedirects();
    }
    if (rootpath === '/guides') {
      handleGuidesHashRedirects();
    }
  }, [rootpath]);

  return null;
}
