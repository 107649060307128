import { generateRedirects } from '../utils';

export function BuildOutputRedirects(): void {
  generateRedirects({
    location: { pathname: location.pathname, hash: location.hash },
    rootPrefix: '/docs/build-output-api/v3',
    sections: [
      {
        from: 'build-output-configuration',
        to: 'configuration',
        applyCharactersReplace: false,
      },
      {
        from: 'vercel-primitives',
        to: 'primitives',
        applyCharactersReplace: false,
      },
      { from: 'features', to: 'features', applyCharactersReplace: false },
    ],
    exceptions: [
      ['build-output-api#assets', 'build-output-api#resources'],
      [
        'build-output-api/v3/features#preview-mode',
        'build-output-api/v3/features#draft-mode',
      ],
      [
        'build-output-api#assets/static-files',
        'build-output-api#resources/static-files',
      ],
      [
        'build-output-api#assets/serverless-functions',
        'build-output-api#resources/pages',
      ],
    ],
    wordsRequiringReplace: [
      { from: 'clean-urls', to: 'cleanurls' },
      { from: 'node-js-config', to: 'node.js-config' },
      {
        from: 'serverless-functions/configuration',
        to: 'serverless-function-configuration',
      },
      {
        from: 'edge-functions/configuration',
        to: 'edge-function-configuration',
      },
      {
        from: 'prerender-functions/configuration',
        to: 'prerender-configuration-file',
      },
      { from: 'introduction/', to: '' },
    ],
  });
}
