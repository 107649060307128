export const guides2GuidesHashRedirects = [
  [
    'deploying-yarn-monorepos-to-vercel#create-the-frontend-and-backend-workspaces-for-a-create-react-app-application',
    'deploying-yarn-monorepos-to-vercel#create-a-workspace-for-a-create-react-app-application',
  ],
  [
    'deploying-yarn-monorepos-to-vercel#use-the-function-inside-next.js-and-the-frontend-of-create-react-app',
    'deploying-yarn-monorepos-to-vercel#use-the-function-inside-both-applications',
  ],
];

export function handleGuidesHashRedirects() {
  /**
 This file has the redirects for all paths under `/guides`
 This is a legacy format and not used anymore since the guides have moved to contentful
 */
  const match = location.pathname + location.hash;
  const generalPrefix = '/guides/';

  for (let index = 0; index < guides2GuidesHashRedirects.length; ++index) {
    const item = guides2GuidesHashRedirects[index];
    if (`${generalPrefix}${item[0]}` === match) {
      document.location = `${generalPrefix}${item[1]}`;
      return;
    }
  }
}
