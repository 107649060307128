'use client';
import { useState, type JSX } from 'react';
import { usePathname } from 'next/navigation';
import { getSecondaryNavigation } from '@pyra/docs-shared/data/navigation/utils';
import { primaryNavigation } from '@pyra/docs-shared/data/navigation/primary';
import { hideSidebarPaths } from '@pyra/docs-shared/data/navigation/documentation/hide';
import { Sidebar } from '#/app/components/layout/sidebar';
import { SideNav } from '#/app/components/layout/navbar/side-nav';

export function SidebarWrapper(): JSX.Element {
  const path = usePathname() as string;
  const secondaryNavigation = getSecondaryNavigation(primaryNavigation, path);
  const [navigationActive, setNavigationActive] = useState(false);
  let hideSidebar = false;
  hideSidebar =
    hideSidebarPaths.includes(path) ||
    (secondaryNavigation.currentItem?.href === '' && path !== '/docs');
  if (!hideSidebar) {
    const includePaths = hideSidebarPaths.filter((p) => p.includes('/**'));
    includePaths.forEach((p) => {
      if (path.includes(p.replace('**', ''))) {
        hideSidebar = true;
      }
    });
  }
  const handleIndexClick = (): void => {
    if (navigationActive) {
      setNavigationActive(false);
    }
  };
  return (
    <Sidebar
      active={navigationActive}
      handleIndexClick={handleIndexClick}
      hasSecondaryNav={secondaryNavigation.navItems.length > 0}
      hideSidebar={hideSidebar}
      isDocsHomepage={path === '/docs'}
    >
      <SideNav />
    </Sidebar>
  );
}
