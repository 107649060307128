import type { ConfigProps } from './types';

const DOCSROOT = '/docs/';

const convertErrorFormat = (
  input: string,
  regexExp: RegExp,
): string | boolean => {
  const regex = regexExp;
  const match = input.match(regex);
  if (match?.groups) {
    const errorDesc = match.groups.description.toUpperCase();
    return input.replace(regex, `/${errorDesc}`);
  }
  return false;
};

export function generateRedirects(config: ConfigProps): void {
  const rootPrefix = config.rootPrefix;
  const rootPathsNo = rootPrefix.split('/').length;
  const match = config.location.pathname + config.location.hash;
  const exceptions = config.exceptions ? config.exceptions : [];
  const sections = config.sections;
  const wordsRequiringReplace = config.wordsRequiringReplace
    ? config.wordsRequiringReplace
    : [];
  const charactersToReplace = config.charactersToReplace
    ? config.charactersToReplace
    : [];
  let matchedExceptions: string[][] = [];
  if (exceptions.length > 0) {
    matchedExceptions = exceptions.filter(
      (exception) => match === `${DOCSROOT}${exception[0]}`,
    );
  }
  if (matchedExceptions.length === 1) {
    const newLoc = `${DOCSROOT}${matchedExceptions[0][1]}`;
    document.location = newLoc;
    return;
  }
  const matchedSections = sections.filter((section) =>
    match.includes(`#${section.from}`),
  );
  const matchWordReplace = wordsRequiringReplace.filter((word) =>
    match.includes(word.from),
  );
  if (config.regexReplace) {
    let regexNewLoc;
    if (Array.isArray(config.regexReplace)) {
      config.regexReplace.forEach((regexExp) => {
        const regExMatch = convertErrorFormat(match, regexExp);
        if (regExMatch !== false) {
          regexNewLoc = regExMatch;
        }
      });
    } else {
      const regExMatch = convertErrorFormat(match, config.regexReplace);
      regexNewLoc = regExMatch;
    }
    if (
      //The conditions are to prevent redirect recursion
      regexNewLoc !== undefined &&
      regexNewLoc !== false &&
      regexNewLoc !== ''
    ) {
      document.location = regexNewLoc as string;
    }
    return;
  }
  if (matchedSections.length === 1) {
    const pathFix = match.replace(
      `#${matchedSections[0].from}`,
      `/${matchedSections[0].to}`,
    );
    const pathItems = pathFix.split('/');
    if (pathItems.length === rootPathsNo) {
      const newLoc = pathFix;
      document.location = newLoc;
      return;
    }
    if (pathItems.length > rootPathsNo) {
      const newLoc = `${rootPrefix}/${matchedSections[0].to}#${
        // eslint-disable-next-line no-nested-ternary
        wordsRequiringReplace.length > 0 &&
        //We should refactor this in a function that does the work
        matchWordReplace.length > 0
          ? matchWordReplace[0].to
          : matchedSections[0].applyCharactersReplace
            ? pathItems[pathItems.length - 1].replaceAll(
                charactersToReplace[0].from, //refactor to check for length of charactersToReplace and replace all for all instances
                charactersToReplace[0].to,
              )
            : pathItems[pathItems.length - 1]
      }`;
      document.location = newLoc;
    }
  } else if (matchWordReplace.length > 0) {
    const newLoc = match.replace(
      matchWordReplace[0].from,
      matchWordReplace[0].to,
    );
    document.location = newLoc;
  }
}
