import { generateRedirects } from '../utils';

export function SpeedInsightsRedirects(): void {
  generateRedirects({
    location: { pathname: location.pathname, hash: location.hash },
    rootPrefix: '/docs/speed-insights',
    sections: [],
    exceptions: [
      [
        'speed-insights/disable#disable-analytics',
        'speed-insights/disable#disable-speed-insights',
      ],
      [
        'speed-insights/disable#identifying-if-analytics-is-enabled',
        'speed-insights/disable#identifying-if-speed-insights-is-enabled',
      ],
      [
        'speed-insights/metrics#speed-insights-by-page-or-url',
        'speed-insights/metrics#speed-insights-by-route-or-path',
      ],
      [
        'speed-insights/metrics#other-web-vitals',
        'speed-insights/metrics#other-metrics',
      ],
    ],
  });
}
