import type { MutableRefObject } from 'react';
import { useEffect, useRef, useState } from 'react';
import type { SideBarItem } from '@pyra/docs-shared/data/navigation/types';
import { trimPath } from '@pyra/docs-shared/data/navigation/utils';
import { NavTree } from './nav-tree';

interface MenuProps {
  dynamicMenu: SideBarItem[];
  path: string;
  alwaysShowNav?: boolean;
}

const getInitialOpenedItems = ({
  dynamicMenu,
  path,
  alwaysShowNav,
}: MenuProps): string[] => {
  const initialOpenedItems: string[] = [];

  const opened = dynamicMenu.filter((item) => item.open);

  const matchItem = [];

  function findMatchPath(
    href: string | null,
    menuData: SideBarItem[],
    openedItems: string[],
    depth = 0,
  ): boolean {
    let foundMatch = false;
    for (const item of menuData) {
      const matchPath = trimPath(item.href);
      if (href && matchPath === href) {
        if (item.posts) {
          openedItems.push(item.href);
        }
        foundMatch = true;
        matchItem.push({ path: matchPath, depth });
        break;
      } else if (item.isDynamic && matchPath && href?.includes(matchPath)) {
        if (item.posts) {
          openedItems.push(item.href);
        }
        foundMatch = true;
        matchItem.push({ path: matchPath, depth });
      }
      if (item.posts) {
        const childMatch = findMatchPath(
          href,
          item.posts,
          openedItems,
          depth + 1,
        );
        if (childMatch) {
          openedItems.push(item.href);
          foundMatch = true;
          matchItem.push({ path: matchPath, depth });
          break;
        }
      }
    }
    return foundMatch;
  }
  findMatchPath(path, dynamicMenu, initialOpenedItems) || alwaysShowNav;

  //Only initially open sections if the path level of the match is at level 1
  if (matchItem.length < 3) {
    opened.forEach((item) => {
      initialOpenedItems.push(item.href);
    });
  }
  return initialOpenedItems;
};

export function Navigation(props: MenuProps): JSX.Element {
  const { dynamicMenu, path, alwaysShowNav = false } = props;
  const triggerRef = useRef<HTMLButtonElement | null | undefined>(null);
  const triggerParentRef = useRef<HTMLButtonElement | null | undefined>(null);
  const eventRef = useRef<KeyboardEvent | null>(null);
  const [openedItems, setOpenedItems] = useState<string[]>(
    getInitialOpenedItems({ dynamicMenu, path, alwaysShowNav }),
  );

  const onItemFocus = (trigger: HTMLButtonElement): void => {
    if (triggerParentRef.current) triggerParentRef.current = null;
    triggerRef.current = trigger as HTMLButtonElement;
  };

  const toggleItem = (name: string | string[]): void => {
    setOpenedItems((prevItems) => {
      const newSet = new Set(prevItems);
      if (typeof name === 'string') {
        newSet[newSet.has(name) ? 'delete' : 'add'](name);
      } else {
        for (const item of name)
          newSet[newSet.has(item) ? 'delete' : 'add'](item);
      }
      return Array.from(newSet);
    });
  };

  useEffect(() => {
    const collapse = (
      trigger: MutableRefObject<HTMLButtonElement | undefined | null>,
    ): void => {
      if (trigger.current) {
        const names: string[] = [];
        const activeChildren = trigger.current.parentNode?.querySelectorAll(
          '[aria-expanded="true"]',
        );
        if (activeChildren) {
          activeChildren.forEach((child) => {
            const name = child.getAttribute('data-name');
            if (name) names.push(name);
          });
        }
        const name = trigger.current.dataset.name;
        const activeElement = trigger.current;
        trigger.current.focus();
        trigger.current = null;
        if (names.length) toggleItem(names);
        const activeParent = activeElement.closest(
          `li:not(:has(> button[data-name="${name}"]))`,
        );
        triggerParentRef.current =
          activeParent?.querySelector('button') || null;
      }
    };
    const handleEscape = (event: KeyboardEvent): void => {
      if (eventRef.current === event) return;
      eventRef.current = event;
      if (
        event.key === 'Escape' &&
        (triggerRef.current || triggerParentRef.current)
      ) {
        collapse(triggerParentRef.current ? triggerParentRef : triggerRef);
      }
    };
    document.body.addEventListener('keydown', handleEscape);
  }, []);

  useEffect(() => {
    const trigger = document.querySelector(`[data-name="${path}"]`);
    if (trigger && !trigger.matches('[aria-expanded="true"]')) toggleItem(path);
  }, [path]);

  // Sync navigation entries on route change
  useEffect(() => {
    setOpenedItems((opened) => [
      ...opened,
      ...getInitialOpenedItems({ dynamicMenu, path, alwaysShowNav }),
    ]);
  }, [path, alwaysShowNav, dynamicMenu]);

  return (
    <nav className="w-full bg-[var(--geist-background)]">
      <ul className="m-0 list-none p-0">
        <NavTree
          data={dynamicMenu}
          isRoot
          onItemFocus={onItemFocus}
          openedItems={openedItems}
          path={path}
          toggleItem={toggleItem}
        />
      </ul>
    </nav>
  );
}
