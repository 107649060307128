'use client';

import { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import { CornerDownRight, ChevronDown } from 'geist/icons';
import { Drawer } from 'geist/components';
import { tid } from '@vercel/geist-test-utils';
import { withAwaitPaint } from '@pyra/components/rendering/await-paint';
import { primaryNavigation } from '@pyra/docs-shared/data/navigation/primary';
import { documentationObject } from '@pyra/docs-shared/data/navigation/documentation/index';
import { getSecondaryNavigation } from '@pyra/docs-shared/data/navigation/utils';
import type { SideBarItem } from '@pyra/docs-shared/data/navigation/types';
import { frameworks } from '#/app/components/switchers/data';
import { Navigation } from '../navigation';
import { FrameworkTrigger } from './framework-trigger';
import { FrameworkSelect } from './framework-select';
import { BackToTop } from './back-to-top';

export function BottomSelectNav(): JSX.Element {
  const path = usePathname() as string;
  let navigationData: SideBarItem[] | undefined;
  const [showMenuSelector, setShowMenuSelector] = useState(false);
  const [showFrameworkSelector, setShowFrameworkSelector] = useState(false);

  useEffect(() => {
    //when the page loads hide both drawers
    setShowMenuSelector(false);
    setShowFrameworkSelector(false);
  }, [path]);

  const secondaryNavigation = getSecondaryNavigation(primaryNavigation, path);
  const showNav =
    secondaryNavigation.currentItem?.items &&
    secondaryNavigation.currentItem.items.length > 0;
  if (
    secondaryNavigation.currentItem &&
    'key' in secondaryNavigation.currentItem &&
    secondaryNavigation.currentItem.key
  ) {
    navigationData =
      documentationObject[secondaryNavigation.currentItem.key].posts;
  } else {
    const navigationKeys = Object.keys(documentationObject);
    navigationData = documentationObject[navigationKeys[0]].posts;
  }

  const currentItem = secondaryNavigation.currentItem?.isRoot
    ? secondaryNavigation.currentItem.name
    : secondaryNavigation.currentItem?.items?.[
        secondaryNavigation.currentItem.index > -1
          ? secondaryNavigation.currentItem.index
          : 0
      ].name;

  function handleSelectAction(content: string): void {
    if (content === 'menu') {
      if (showFrameworkSelector) {
        setShowFrameworkSelector(false);
      }
      setShowMenuSelector(!showMenuSelector);
    }
    if (content === 'framework') {
      if (showMenuSelector) {
        setShowMenuSelector(false);
      }
      setShowFrameworkSelector(!showFrameworkSelector);
    }
  }

  const supportedFrameworks = frameworks.filter((item) =>
    item.supportedFeatures?.some((feature) => path.includes(feature)),
  );

  const handleIndexClick = (): void => {
    if (showMenuSelector) {
      setShowMenuSelector(false);
    }
    if (showFrameworkSelector) {
      setShowFrameworkSelector(false);
    }
  };

  // TODO: Fix setTimeout reliance for Drawer close
  return (
    <>
      {Boolean(showNav) && (
        <div className="fixed bottom-0 left-0 right-0 flex max-w-full content-center items-center border-0 border-t-[1px] border-solid border-t-[var(--accents-2)] bg-[var(--geist-background)] px-[16px] py-[12px] lg:hidden">
          <button
            className="flex flex-1 cursor-pointer justify-center gap-[10px] rounded-[8px] border-[1px] border-solid border-[var(--accents-2)] bg-transparent p-[8px]"
            data-testid={tid('mobile-menu', 'trigger')}
            onClick={withAwaitPaint((): void => {
              handleIndexClick();
              handleSelectAction('menu');
            })}
            type="button"
          >
            <CornerDownRight size={16} />
            <span className="flex flex-1 justify-start overflow-hidden overflow-ellipsis whitespace-nowrap text-[14px] font-[500] text-[var(--geist-foreground)]">
              {currentItem?.toString()}
            </span>
            <ChevronDown size={16} />
          </button>
          {supportedFrameworks.length > 0 && (
            <button
              className="ml-[12px] flex cursor-pointer justify-center gap-[10px] rounded-[8px] border-[1px] border-solid border-[var(--accents-2)] bg-transparent p-[8px] md:hidden"
              onClick={withAwaitPaint((): void => {
                handleIndexClick();
                handleSelectAction('framework');
              })}
              type="button"
            >
              <FrameworkTrigger selectorOpened={showFrameworkSelector} />
            </button>
          )}
          <BackToTop size={26} wrap />
        </div>
      )}
      <Drawer
        onDismiss={(): void => setShowMenuSelector(false)}
        show={showMenuSelector}
      >
        <div
          className="px-[16px] py-[12px]"
          data-testid={tid('mobile-menu', 'drawer')}
        >
          <Navigation
            alwaysShowNav={false}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            dynamicMenu={navigationData!}
            path={path}
          />
        </div>
      </Drawer>
      <Drawer
        onDismiss={(): void => setShowFrameworkSelector(false)}
        show={showFrameworkSelector}
      >
        <div className="px-[16px] py-[12px]">
          <FrameworkSelect
            handleIndexClick={withAwaitPaint((): void => {
              handleIndexClick();
              handleSelectAction('framework');
            })}
            pathname={path}
          />
        </div>
      </Drawer>
    </>
  );
}
