'use client';

import { useEffect, useState } from 'react';
import { useTheme } from 'geist/core';
import { ChevronDown } from 'geist/icons';
import { useSwitcherContext } from '#/app/components/switchers/context';

export function FrameworkTrigger({
  selectorOpened = false,
}: {
  selectorOpened: boolean;
}): JSX.Element {
  const { resolvedTheme } = useTheme();
  const { framework } = useSwitcherContext();
  const [selected, setSelected] = useState(framework.name || 'Fetching...');

  useEffect(() => {
    setSelected(framework.name);
  }, [framework, selected]);

  return (
    <div className="flex w-full cursor-pointer items-center gap-[8px] [&>div:first-of-type]:flex [&_div]:items-center">
      <div>
        {resolvedTheme === 'dark'
          ? framework.icons.dark
          : framework.icons.light}
      </div>
      <div className="hidden flex-1 items-center overflow-hidden overflow-ellipsis whitespace-nowrap text-[13px] leading-[175%] text-[var(--geist-foreground)] md:flex">
        {framework.name}
      </div>
      <ChevronDown
        className={`${selectorOpened ? 'rotate-180' : ''} motion-safe:transition-transform`}
        size={16}
      />
    </div>
  );
}
