import { clsx } from 'clsx';
import { useTheme } from 'geist/core';
import { Check } from 'geist/icons';
import type { JSX } from 'react';
import { useSwitcherContext } from '#/app/components/switchers/context';
import { frameworks } from '#/app/components/switchers/data';
import styles from './framework-select.module.css';

export function FrameworkSelect({
  pathname,
  handleIndexClick,
}: {
  pathname: string;
  handleIndexClick: () => void;
}): JSX.Element {
  const { framework, toggleFramework } = useSwitcherContext();

  const supportedFrameworks = frameworks.filter((item) =>
    item.supportedFeatures?.some((feature) => pathname.includes(feature)),
  );

  const { resolvedTheme } = useTheme();

  function handleSelection(value: string): void {
    const fw = frameworks.find((item) => item.name === value);
    handleIndexClick();
    if (!fw) return;
    toggleFramework(fw.slug);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.info}>
        Choose a framework to optimize documentation to:
      </div>
      <ul>
        {supportedFrameworks.map((item) => (
          <li key={item.name} value={item.name}>
            <button
              onClick={(): void => handleSelection(item.name)}
              type="button"
            >
              <div className={styles.fwIcon}>
                {resolvedTheme === 'dark' ? item.icons.dark : item.icons.light}
              </div>
              <span
                className={
                  item.name === framework.name
                    ? clsx(styles.selectItem, styles.currentItem)
                    : styles.selectItem
                }
              >
                {item.name}
              </span>
              <Check
                className={
                  item.name === framework.name
                    ? styles.showCheck
                    : styles.hideCheck
                }
                size={16}
              />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
