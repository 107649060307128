'use client';

import { clsx } from 'clsx';
import type { ReactNode, RefObject, JSX } from 'react';
import React, { useEffect, useRef } from 'react';
import styles from './sidebar.module.css';

interface SidebarProps {
  active?: boolean;
  isDocsHomepage?: boolean;
  hasSecondaryNav?: boolean;
  children: ReactNode;
  innerRef?: RefObject<HTMLElement>;
  fixed?: boolean;
  isGuide?: boolean;
  handleIndexClick?: () => void;
  hideSidebar?: boolean;
}

export function Sidebar({
  active,
  isDocsHomepage = false,
  hasSecondaryNav = false,
  children,
  innerRef,
  fixed,
  isGuide = false,
  handleIndexClick,
  hideSidebar = false,
}: SidebarProps): JSX.Element {
  const sideBarContainerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const sideBarContainer = sideBarContainerRef.current;
    const selectedNav = document.getElementsByClassName('nav-link selected');

    if (
      selectedNav.length > 0 &&
      selectedNav[0] instanceof HTMLElement &&
      sideBarContainer instanceof HTMLElement
    ) {
      const selectedNavElement = selectedNav[0];
      if (
        selectedNavElement.offsetTop + selectedNavElement.offsetHeight >
        sideBarContainer.offsetHeight / 2
      ) {
        sideBarContainer.scrollTo({
          top: selectedNavElement.offsetTop - selectedNavElement.offsetHeight,
          behavior: 'auto',
        });
      }
    }
  }, [sideBarContainerRef]);

  function handleKeyDown(e: React.KeyboardEvent<HTMLElement>): void {
    if (e.key === 'Enter' && handleIndexClick) {
      handleIndexClick();
    }
  }

  return (
    <aside
      className={
        isGuide
          ? clsx(styles.sidebarGuides, {
              active,
              fixed,
              [styles.sidebarWithStyledScrollbar]: !isDocsHomepage,
            })
          : clsx(styles.sidebar, {
              active,
              fixed,
              [styles.sidebarGuidesBackground]: !isDocsHomepage,
              [styles.sidebarWithStyledScrollbar]: !isDocsHomepage,
              [styles.sidebarWithSecondaryNav]: hasSecondaryNav,
              [styles.hideSidebar]: hideSidebar,
            })
      }
      onClick={handleIndexClick}
      onKeyDown={handleKeyDown}
      ref={innerRef ? innerRef : sideBarContainerRef}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="button"
      tabIndex={0}
    >
      <div>{children}</div>
    </aside>
  );
}
